import React from 'react';
import ChampionImageWithLane from "../common/ChampionImageWithLane";
import SmallInfluInfo from "../common/SmallInfluInfo";
import BigTooltip from "../Tooltip/BigTooltipBox";
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import ToSummonerLink from "../Link/ToSummonerLink";


const ListWrapper = styled.div`
  width: 110px;

  p {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: break-word;
    text-align: left;
    color: ${props => props.theme.color.font_58_CE};
    opacity: 1;
    font-weight: 400 !important;
    line-height: 16px;

    &:hover {
      color: ${props => props.theme.color.font_000};
      text-decoration: underline;
    }
  }

  .my {
    span {
      color: ${props => props.theme.color.dark_100};
      font-weight: bold;
      opacity: 1;
    }

    .imgChamp {
      border-radius: 50%;
    }
  }

  .myTogether {
    span {
      color: #6A5EDC;
      font-weight: bold;
      opacity: 1;
    }

    .imgChamp {
      border-radius: 50%;
    }
  }

  .together {
    span {
      color: #6A5EDC;
      opacity: 1;
    }
  }

`;
const SmallChampImage = styled.div`
  display: flex;
  flex-direction: row;
  z-index: 2;

  .imgDiv {
    border-radius: ${props => props.borderRadius};
  }
`;
const PlayerMarginBox = styled.div`
  margin-right: 4px;
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 22px;
  row-gap: 15px;
`;

const MatchSwarmSummoner = ({list, getName, myMatch, hide, getPClassName, region, match}) => {
    const {t} = useTranslation();


    return (
        <Wrapper>
            {list.map((player, index) => {
                const name = getName(player);
                return (
                    <ListWrapper>
                        <SmallChampImage
                            borderRadius={hide ? '3px' : player.puu_id === myMatch.puu_id ? '50%' : '3px'}
                            onClick={(e) => {
                                e.stopPropagation()
                            }}
                            key={index}
                        >

                            <PlayerMarginBox>
                                <ChampionImageWithLane
                                    disabled={true}
                                    champion_id={player.champion_id}
                                />
                            </PlayerMarginBox>
                            {player.pro_streamer_status &&
                                <PlayerMarginBox>
                                    <SmallInfluInfo status={player.pro_streamer_status}/>
                                </PlayerMarginBox>}
                            <p className={getPClassName(player.puu_id)}>
                                <ToSummonerLink
                                    name={player.riot_id_tag_line ? `${player.riot_id_name}-${player.riot_id_tag_line}` : name}
                                    region={region}
                                    puu_id={player.puu_id}
                                >
                                                    <span
                                                        className="common_search"
                                                    >

                                                        {name}
                                                    </span>
                                </ToSummonerLink>
                            </p>
                            {(player?.withTogether && match?.togetherCount > 1) &&
                                <BigTooltip tooltipText={t('summoner.withTogether')}>
                                    <img src={'/images/ico_team.svg'} width={12} height={12} alt={""}/>
                                </BigTooltip>
                            }
                        </SmallChampImage>
                    </ListWrapper>
                );
            })}
        </Wrapper>
    );
};

export default MatchSwarmSummoner;