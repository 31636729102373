import React, {useCallback} from 'react';
import styled from "styled-components";
import TierTag from "../../../TierTag";
import {ColumnBox, FlexBox} from "../../../../ui/styled";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-block: 4px;
  padding-inline: 4px;
  margin-right: 6px;
  cursor: pointer;
  border-radius: 4px;

  &:hover {
    background-color: ${props => props.theme.color.white_200};
  }
`;
const ImgWrapper = styled.div`
  background-color: ${props => props.theme.color.white_200};
  border-radius: 6px;
  margin-right: 2px;

  img {
    object-fit: cover;
    border-radius: 6px;
  }
`;

const Tier = styled.div`
  margin-right: 4px;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const Text = styled.span`
  font-size: 14px;
  margin-right: 4px;
  color: ${props => props.theme.color.dark_100};
  font-weight: 500;
  margin-left: 4px;
`;

const RegionText = styled.span`
  font-size: 12px;
  opacity: 0.7;
  color: ${props => props.theme.color.dark_100};
`;

const InfluText = styled(RegionText)`
`;

const TeamImg = styled.img`
  margin-right: 4px;
`;

const TagText = styled.span`
  color: ${props => props.theme.color.dark_200};
  font-weight: 500;
  margin-left: 5px;
`;


const AutoCompleteContent = ({
                                 tag,
                                 errorImg,
                                 img,
                                 tier,
                                 division,
                                 name,
                                 onClick,
                                 data,
                                 regionText,
                                 teamLogo,
                                 influName
                             }) => {
    const onClickWrapper = useCallback((event) => {
        event.stopPropagation();
        if (data) {
            onClick(data);
        }
    }, [onClick, data]);


    if (!name) {
        return null;
    }


    return (
        <Wrapper onClick={onClickWrapper}>
            <ImgWrapper>
                <img
                    src={img}
                    width={32}
                    height={32}
                    alt={""}
                    onError={(e) => {
                        if (errorImg) {
                            e.target.src = errorImg
                        }
                    }}/>
            </ImgWrapper>
            <ColumnBox margin={'0 0 0 8px'}>
                <FlexBox>
                    {tier &&
                        <Tier>
                            <TierTag
                                fontSize={'11px'}
                                padding={'4px 4px'}
                                tier={tier}
                                division={division}
                            />
                        </Tier>}
                    {(influName || teamLogo) &&
                        <FlexBox>
                            {teamLogo && <TeamImg
                                src={`${process.env.REACT_APP_ROOT_IMG}assets/team_logos/${teamLogo}.png`}
                                width={24}
                                alt={""}
                                onError={(e) => {
                                    e.target.style.display = 'none'
                                }}
                            />}
                        </FlexBox>}
                    <TextWrapper>
                        <Text>{decodeURI(name)}{tag && <TagText>#{decodeURI(tag)}</TagText>}</Text>
                        {regionText && <RegionText>({regionText})</RegionText>}
                    </TextWrapper>
                </FlexBox>

            </ColumnBox>
        </Wrapper>
    );
};

export default AutoCompleteContent;
