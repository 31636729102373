import React from 'react';
import useQueueId from "./useQueueId";
import {Trans, useTranslation} from "react-i18next";
import {getRegionServer} from "../../function";

const UseMarqueeDescription = () => {
    const {t,i18n} = useTranslation()
    const {getMatchName} = useQueueId();


    const getStatus = (status) => {
        if (status === 'Streamer') {
            return t("autoComplete.streamer")
        } else if (status.toLowerCase() === 'pro') {
            return t("autoComplete.pro")
        }
        return ""
    }

    const transDescription = (data) => {
        const descriptionNumber = Number(data.description);

        switch (descriptionNumber) {

            // t={('news.news02', {
            //     player1: data.name,
            //     player2: data.versus,
            // })}
            case 2:
                return <Trans
                    i18nKey={'news.news02'}
                    values={{
                        player1: data.name,
                        player2: data.versus
                    }}
                />
            case 3:
                return t('news.news03', {
                    gameMode: getMatchName(data.queue_id)
                })
            case 4:
                return t('news.news04', {
                    gameMode: getMatchName(data.queue_id)
                })
            case 5:
                return t('news.news05', {
                    grade: t('news.grade', {
                        count: Number(data.versus),
                        ordinal: true,
                    })
                })
            case 6:
                return t('news.news06', {
                    tier: data.versus
                })
            case 7:
                return t('news.news07')
            case 8:
                return data.versus
            case 1000:
                return t('news.news1000', {
                    ai_score: Math.round(data.ai_score),
                    platform_id: getRegionServer(data.platform_id),

                })
            default:
                return ""
        }
    }


    return {
        transDescription
    }
};

export default UseMarqueeDescription;