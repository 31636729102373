import React, {useCallback, useMemo, useState} from 'react';
import styled, {useTheme} from "styled-components";
import RankingTableHeader from "./RankingTableHeader";
import CRTableRanking
    from "../../../../Champions/ChampionsContent/ChampionsRank/ChampionsRankingTable/ChampionsRankingTableContent/CRTableRanking";
import RankingSummoner from "./RankingSummoner";
import RankingTier from "./RankingTier";
import RankingWinRate from "./RankingWinRate";
import RankingMost from "./RankingMost";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import {countryName,} from "../../../../../function";
import BigTooltip from "../../../../Tooltip/BigTooltipBox";
import {dark, light} from "../../../../../common/theme";

const Wrapper = styled.div`
  width: 100%;
`;

const Table = styled.table`
  width: 100%;

  .light + .light {
    border-top: 1px solid ${props => light.gray_200};
  }

  .dark + .dark {
    border-top: 1px solid ${props => dark.gray_200};
  }
`;

const ContentTR = styled.tr`
  overflow: hidden;
  background-color: ${props => props.theme.color.site_main_color};


  &:hover {
    background-color: ${props => props.theme.color.white_200};
  }
  
`;

const Content = styled.td`
  height: 48px;
  vertical-align: middle;
`;

const Thead = styled.thead`
  border-bottom: 1px solid ${props => props.theme.color.default_border_color};
`;


const LevelWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const LevelText = styled.span`
  font-size: 12px;
  text-align: center;
`;

const InfluWrapper = styled.div`
  width: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const TBody = styled.tbody`
`;

const SummonerNameWrapper = styled.div`
  display: flex;
  width: 230px;
`;

const RankingTableContent = ({rankingList, region, version}) => {
    const {t} = useTranslation();
    useHistory();
    const sortedRankingList = useMemo(() => {
        if (Array.isArray(rankingList)) {
            const sort = rankingList.sort((a, b) => a.rank - b.rank);
            return sort;
        } else {
            return [];
        }
    }, [rankingList]);

    const onClickInfluWrapper = useCallback((summonerName, tag) => {
        window.open(`/summoner/${region}/${summonerName}-${tag}`, '_blank');
    }, [region])


    return (
        <Wrapper>
            <Table>
                <colgroup>
                    <col/>
                    <col width={'300px'}/>
                    <col width={'70px'}/>
                </colgroup>
                <Thead>
                    <tr>
                        <RankingTableHeader>{t('ranking.rank')}</RankingTableHeader>
                        <RankingTableHeader>{t('ranking.summoner')}</RankingTableHeader>
                        <RankingTableHeader>{t('proStreamer.region')}</RankingTableHeader>
                        <RankingTableHeader>{t('ranking.tier')}</RankingTableHeader>
                        <RankingTableHeader>{t('ranking.winRate')}</RankingTableHeader>
                        <RankingTableHeader>{t('ranking.level')}</RankingTableHeader>
                        <RankingTableHeader>{t('ranking.mostLane')}</RankingTableHeader>
                        <RankingTableHeader last={true}>{t('ranking.mostChamp')}</RankingTableHeader>
                    </tr>
                </Thead>
                <TBody>
                    {sortedRankingList.map((data) => {
                        return <TR key={data?.riot_id_name + data?.riot_id_tag_line} data={data} region={region}
                                   version={version}/>
                    })}

                </TBody>
            </Table>
        </Wrapper>
    );
};


const TR = ({data, version, region}) => {
    const {i18n} = useTranslation()
    const theme = useTheme();
    const [onHover, setOnHover] = useState(false);

    const handleMouseOver = () => {
        if (data.name) {
            setOnHover(true);
        }
    };

    const handleMouseOut = () => {
        if (data.name) {
            setOnHover(false);
        }
    };
    const onClickSummoner = () => {
        return `${data.riot_id_name}-${data.riot_id_tag_line}`
    }
    return (
        <ContentTR onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} className={theme.color.theme}>
            <Content>
                <CRTableRanking
                    newValue={9999}
                    rankColor={theme.color.dark_200}
                    dictMargin={'30px'}
                    rank={data.rank}
                    value={data.rank_delta}
                />
            </Content>
            <Content>
                <RankingSummoner
                    teamLogo={data?.pro_team}
                    proTeam={data.team_as}
                    onClickSummoner={onClickSummoner}
                    onHover={onHover}
                    proName={data.name}
                    status={data.pro_streamer}
                    tag={data.riot_id_tag_line}
                    streaks={data?.winning}
                    restDays={data.rest_cnt}
                    version={version}
                    region={region}
                    summonerName={data.riot_id_name}
                    puuId={data.puu_id}
                    iconId={Number(data?.icon_id)}
                />
            </Content>
            <Content>
                {(data?.country_iso && data?.country_iso !== '-') &&
                    <BigTooltip tooltipText={countryName(i18n.language, data.country_iso)}>
                        <LevelWrapper>
                            <InfluWrapper>
                                <img
                                    key={data?.country_iso + "nation"}
                                    src={`${process.env.REACT_APP_ROOT_IMG}nation/${data?.country_iso?.toLowerCase()}.png`}
                                    width={24} height={16} alt={''}
                                />
                            </InfluWrapper>
                        </LevelWrapper>
                    </BigTooltip>}
            </Content>
            <Content>
                <RankingTier
                    lp={data.lp}
                    tier={data.tier}
                    division={1}
                />
            </Content>
            <Content>
                <RankingWinRate
                    lose={data.loss_cnt}
                    win={data.win_cnt}
                />
            </Content>
            <Content>
                <LevelWrapper>
                    <LevelText>{data.level}</LevelText>
                </LevelWrapper>
            </Content>

            <Content>
                <LevelWrapper>
                    {data?.most_role &&
                        <img
                            alt={""}
                            width={16} height={16}
                            src={`${theme.icon.path}/icon_s_position_${data?.most_role?.toLowerCase()}.svg`}/>}
                </LevelWrapper>
            </Content>
            <Content>
                <RankingMost
                    list={(data.most_champion).slice(0, 3)}
                />
            </Content>
        </ContentTR>
    )
}

export default RankingTableContent;



