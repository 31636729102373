import React, {useMemo} from 'react';
import styled from "styled-components";
import {FlexBox} from "../../../ui/styled";
import TierTag from "../../../common/TierTag";
import {ImageSpell} from "../../../common";
import ChampionImageWithLane from "../../../common/ChampionImageWithLane";
import InfluInfo from "../../../summoner/Profile/InfluInfo";
import {romanNumberTrans} from "../../../../function";

const Wrapper = styled.div`
  display: flex;
  flex-direction: ${props => {
    let direction = 'row';
    if (props.reverse) direction = 'row-reverse';
    return direction
  }};
  justify-content: space-between;
  align-items: center;
  padding: 2px 10px;
`;

const TextWrapper = styled.div`
  margin: ${props => {
    let margin = '0 0 0 7px';
    if (props.reverse) margin = '0 7px 0 0';
    return margin
  }};
  display: flex;
  flex-direction: column;
  justify-content: ${props => {
    let content = 'center';
    if (props.status) content = 'space-between';
    return content
  }};
  height: 42px;

  .influ-name {
    opacity: 0.6;
  }

  .influ-wrapper {
    justify-content: center;
  }
`;

const SummonerName = styled.span`
  display: flex;
  flex-direction: ${props => {
    let direction = 'row';
    if (props.reverse) direction = 'row-reverse';
    return direction
  }};
  font-size: 12px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;
const SpellWrapper = styled.div`
  margin: ${props => {
    let margin = '0 0 0 3px';
    if (props.reverse) margin = '0 3px 0 0';
    return margin
  }};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 42px;

  .imgSpell {
    width: 19px;
    height: 19px;
    border-radius: 3px;
  }
`;

const ChampWrapper = styled.div`
  .imgDiv {
    border-radius: 5px;
  }


  .imgChamp {
    height: 48px;
    width: 48px;
  }
`;

const TagText = styled.span`
  color: ${props => props.theme.color.font_7F_89};
  margin: ${props => {
    let margin = '0 0 0 3px';
    if (props.reverse) margin = '0 3px 0 0';
    return margin
  }};
`;

const FlexBoxDirection = styled(FlexBox)`
  flex-direction: ${props => {
    let direction = 'row';
    if (props.reverse) direction = 'row-reverse';
    return direction
  }};
`;
const SpectateLiveSummonerItem = ({reverse, data, region}) => {
    const tierMeme = useMemo(() => {
        try {
            const splitTier = data.tier.split(' ');
            const division = romanNumberTrans(splitTier[1]);
            const tier = splitTier[0];
            return {tier, division}
        } catch (e) {
            return {
                tier: 'unranked',
                division: ""
            }
        }
    }, []);

    return (
        <Wrapper className={'live-item'} reverse={reverse}>
            <TierTag
                fontSize={'11px'}
                padding={'4px 4px'}
                tier={tierMeme.tier}
                division={tierMeme.division}
            />
            <FlexBoxDirection reverse={!reverse}>
                <ChampWrapper>
                    <ChampionImageWithLane champion_id={data.champion_id}/>
                </ChampWrapper>
                <SpellWrapper reverse={!reverse}>
                    <ImageSpell spellId={data.spell1} smite={true}/>
                    <ImageSpell spellId={data.spell2} smite={true}/>
                </SpellWrapper>
                <TextWrapper reverse={!reverse} status={Boolean(data.status)}>
                    <FlexBox height={'19px'} justifyContent={!reverse ? 'flex-end' : "flex-start"}>
{/*
                        <SummonerName

                            onClick={() => onStorePuuId(`${data.riot_id_name}-${data.riot_id_tag_line}`, getRegionServer(region).toLowerCase())}>{data.riot_id_name}<TagText>#{data.riot_id_tag_line}</TagText></SummonerName
                        >
*/}
                    </FlexBox>
                    {data.status &&
                        <FlexBox height={'19px'} justifyContent={!reverse ? 'flex-end' : "flex-start"}>
                            <InfluInfo
                                direction={!reverse ? 'row-reverse' : 'row'}
                                influData={{
                                    status: data.status,
                                    name: data.name,
                                    url_name: data.url_name
                                }}
                            />
                        </FlexBox>}
                </TextWrapper>
            </FlexBoxDirection>
        </Wrapper>
    );
}
export default SpectateLiveSummonerItem;