import React, {useEffect} from 'react';
import {useQueryClient} from "@tanstack/react-query";
import {useTranslation} from "react-i18next";
import {SET_ARGUMENT_INFO, SET_ITEM, SET_NOTICE, SET_RUNE, SET_SPELL} from "../../store/reducer/imageStore";
import {useDispatch} from "react-redux";

const SetDefaultDataLayer = ({children}) => {
    const dispatch = useDispatch();
    const {i18n} = useTranslation()
    const queryClient = useQueryClient();
    const augments = queryClient.getQueryData(['augments', i18n.language])
    const appRune = queryClient.getQueryData(['appRune', i18n.language])
    const appSpell = queryClient.getQueryData(['appSpell', i18n.language]);
    const appItem = queryClient.getQueryData(['appItem', i18n.language])
    const appNotice = queryClient.getQueryData(['appNotice', i18n.language])


    useEffect(() => {
        if (augments) {
            dispatch({
                type: SET_ARGUMENT_INFO,
                data: augments,
            })
        }

    }, [augments])

    useEffect(() => {
        if (appRune) {
            dispatch({
                type: SET_RUNE,
                data: appRune,
            })
        }
    }, [appRune])


    useEffect(() => {
        if (appSpell) {
            dispatch({
                type: SET_SPELL,
                data: appSpell,
            })
        }
    }, [appSpell])

    useEffect(() => {
        if (appItem) {
            dispatch({
                type: SET_ITEM,
                data: appItem,
            })
        }
    }, [appItem])


    useEffect(() => {
        if (appNotice) {
            dispatch({
                type: SET_NOTICE,
                data: appNotice,
            })
        }
    }, [appNotice])





    return children;
};

export default SetDefaultDataLayer;