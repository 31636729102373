import React from 'react';
import styled from "styled-components";
import InfluIcon from "../Profile/InfluInfo/InfluIcon";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import NewTabLink from "../../Link/NewTabLink";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 20px;
`;
const CardItem = styled.button`
  height: 100px;
  width: 72px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border: 1px solid ${props => props.theme.color.default_border_color};
  padding: 5px;
  border-radius: 6px;
  background-color: ${props => props.theme.color.site_main_color};

  &:hover {
    background-color: ${props => props.theme.color.white_200};
  }

`;
const Title = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: ${props => props.theme.color.dark_200};
`;
const SummonerText = styled.span`
  font-size: 12px;
  font-weight: 500;
  max-width: 55px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-bottom: 2px;
  color: ${props => props.theme.color.dark_100};
`;
const ProfileImage = styled.img`
  border-radius: 6px;
  object-fit: cover;
`;

const ProfileWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  background-color: ${props => props.theme.color.white_201};
  border-radius: 6px;
`;

const CardListWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 5px;
`;


const ReletiveSummoner = ({
                              list, enTeamName, krTeamName, showTeamName
                          }) => {
        const {t} = useTranslation();
        if (!Array.isArray(list)) {
            return null;
        }
        if (list.length < 1) {
            return null;
        }

        return (
            <Wrapper>
                <TitleBox enName={enTeamName} krName={krTeamName} show={showTeamName}/>
                <CardListWrapper>
                    {list.map((data, index) => {
                        return (
                            <CardBox name={data.name} championship_name={data.championship_name} status={data.status}
                                     url_name={data.url_name} key={index}/>
                        )
                    })}
                </CardListWrapper>
            </Wrapper>
        );
    }
;


const AbsoluteProTag = styled.div`
  position: absolute;
  bottom: -7px;
`;
const CustomLink = styled(NewTabLink)`
  & + & {
    margin-left: 5px;
  }
`;
const CardBox = ({status, name, championship_name, url_name}) => {
    const nameUrl = () => {
        if (status?.toLowerCase() === 'pro' && url_name) {
            return `/pro/${url_name}`
        } else if (status?.toLowerCase() === 'streamer') {
            return (`/strm/${name}`)
        } else {
            console.log('error', name);
            return ""
        }
    }

    return (
        <CustomLink to={nameUrl()}>

            <CardItem>
                <ProfileWrapper>
                    <ProfileImage
                        src={`${process.env.REACT_APP_ROOT_IMG}${status?.toLowerCase()}/${status?.toLowerCase === 'pro' ? championship_name : name}.png`}
                        width={62}
                        height={62}
                        onError={(e) => e.target.src = '/images/img_unknown.svg'}/>
                    <AbsoluteProTag>
                        <InfluIcon status={status}/>
                    </AbsoluteProTag>
                </ProfileWrapper>
                <SummonerText>{name}</SummonerText>
            </CardItem>
        </CustomLink>
    )
}


const TitleBox = ({krName, enName, show}) => {
    const {t} = useTranslation()
    const currentLang = useSelector((state) => state.translation.lang);

    if (!show) {
        return <Title>{t('summoner.related')}</Title>
    }

    if (currentLang === 'KOR' && krName) {
        return <Title>{krName}</Title>
    }

    if (currentLang !== 'KOR' && enName) {
        return <Title>{enName}</Title>
    }

    return <Title>{t('summoner.related')}</Title>
}

export default ReletiveSummoner;