import React, {useCallback, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import {getFormatTime, getNeGa, getWinrateCalc, scoreColor} from '../../function';
import {ChartModalContainer} from '../../styled/SummonerStyle';
import ChampionImageWithLane from "../common/ChampionImageWithLane";
import ChartModalArrow from "./ChartModal/ChartModalArrow";
import styled from "styled-components";
import {ImageMonster} from "../common";
import {ChartAnalysis} from "./index";


const TrophyAbsolute = styled.div`
  position: absolute;
  ${props => {
    if (props.side === 'blue') {
      return "left: 16px;"
    } else {
      return "right: 16px;"
    }
  }};
`;
const ChartModal = ({
                        modalData,
                        modalOpen,
                        handleModalClose,
                        info,
                        originArr,
                        inferenceData,
                    }) => {
        const {t} = useTranslation();
        const currentLang = useSelector((state) => state.translation.lang);
        const [chartModalInfo, setChartModalInfo] = useState(modalData);
        const [selectedInfo, setSelectedInfo] = useState(info);

        useEffect(() => {
            const {body} = document;
            document.body.style.overflow = 'hidden';
            document.body.style.marginRight = '15px'
            return () => {
                document.body.style.overflow = '';
                document.body.style.marginRight = '0'
            }
        }, []);


        // {modalIndex, eventArr, detailEvent, concatEvent} = modalData;
        const handleModalFunction = (data) => {
            setChartModalInfo(data)
        }


        const [data, setData] = useState({});

        const [mapLocation] = useState([
            {RED_TOP_MID_NEXUS_TOWER: {x: 42.29, y: 240.78}},
            {RED_BOT_MID_NEXUS_TOWER: {x: 34.39, y: 232.32}},
            {RED_MID_BASE_TOWER: {x: 69.43, y: 206.25}},
            {RED_TOP_BASE_TOWER: {x: 23.73, y: 195.45}},
            {RED_BOT_BASE_TOWER: {x: 81.03, y: 250.9}},
            {RED_MID_INNER_TOWER: {x: 95.15, y: 185.85}},
            {RED_TOP_INNER_TOWER: {x: 30.05, y: 151.36}},
            {RED_BOT_INNER_TOWER: {x: 129.6, y: 246.7}},
            {RED_MID_OUTER_TOWER: {x: 109.85, y: 156.9}},
            {RED_TOP_OUTER_TOWER: {x: 20.27, y: 82.96}},
            {RED_BOT_OUTER_TOWER: {x: 195.61, y: 255.0}},
            {RED_MID_INHIBITOR: {x: 61.18, y: 215.17}},
            {RED_TOP_INHIBITOR: {x: 23.77, y: 208.54}},
            {RED_BOT_INHIBITOR: {x: 65.77, y: 251.21}},
            {BLUE_TOP_MID_NEXUS_TOWER: {x: 234.41, y: 34.66}},
            {BLUE_BOT_MID_NEXUS_TOWER: {x: 242.53, y: 43.28}},
            {BLUE_MID_BASE_TOWER: {x: 207.21, y: 68.96}},
            {BLUE_TOP_BASE_TOWER: {x: 195.19, y: 24.31}},
            {BLUE_BOT_BASE_TOWER: {x: 253.06, y: 80.57}},
            {BLUE_MID_INNER_TOWER: {x: 182.04, y: 88.96}},
            {BLUE_TOP_INNER_TOWER: {x: 148.46, y: 28.68}},
            {BLUE_BOT_INNER_TOWER: {x: 247.59, y: 123.45}},
            {BLUE_MID_OUTER_TOWER: {x: 167.09, y: 118.26}},
            {BLUE_TOP_OUTER_TOWER: {x: 81.71, y: 20.2}},
            {BLUE_BOT_OUTER_TOWER: {x: 257.51, y: 191.46}},
            {BLUE_MID_INHIBITOR: {x: 215.76, y: 60.56}},
            {BLUE_TOP_INHIBITOR: {x: 209.55, y: 23.83}},
            {BLUE_BOT_INHIBITOR: {x: 252.69, y: 66.97}},
        ]); //타워,억제기 좌표

        const initialFunc = useCallback(() => {

            console.log("???", chartModalInfo)

            chartModalInfo.eventArr.forEach((item, index) => {
                if (chartModalInfo.modalIndex === index) {


                    // 이벤트 배열 만들기
                    const eventArr = [];
                    chartModalInfo.concatEvent[index]?.forEach((data) => {
                        if (data?.event?.type !== 'Item') {
                            eventArr.push(data)
                        }
                    });

                    // 이벤트 객체 만들기
                    let eventObj = {
                        win_rate: 0,
                    };


                    const currentEventArray = chartModalInfo.concatEvent[index];
                    const eventObjOriginal = currentEventArray[currentEventArray.length - 1];


                    eventObj = {
                        ...eventObj,
                        win_rate: eventObjOriginal.win_rate,
                        win_rate_diff: eventObjOriginal.win_rate_delta,
                        ...chartModalInfo.aiScoreArr[index],
                        time: currentEventArray[0].time
                    }

                    const levelObj = {
                        ...eventObjOriginal.level
                    }


                    // 최소,최대 시간
                    let time = [];
                    eventArr.forEach((itm) => {
                        time.push(itm.time);
                    });

                    // 데이터
                    let dataBinding = {
                        event: eventArr,
                        tower: chartModalInfo.originalEvent.map((data) => data.building)[index],
                        level: {
                            ...levelObj
                        },
                        team: {...eventObj},
                        minTime: time[0],
                        maxTime: time[time.length - 1],
                        total: {
                            B_Bot: 0,
                            B_Jun: 0,
                            B_Mid: 0,
                            B_Sup: 0,
                            B_Top: 0,
                            R_Bot: 0,
                            R_Jun: 0,
                            R_Mid: 0,
                            R_Sup: 0,
                            R_Top: 0,
                        },
                    };


                    // 교전 정보 데이터 가공
                    dataBinding.event.forEach((itm) => {
                        // 이벤트 점수 합계
                        dataBinding.total.B_Bot += itm.B_Bot;
                        dataBinding.total.B_Jun += itm.B_Jun;
                        dataBinding.total.B_Mid += itm.B_Mid;
                        dataBinding.total.B_Sup += itm.B_Sup;
                        dataBinding.total.B_Top += itm.B_Top;
                        dataBinding.total.R_Bot += itm.R_Bot;
                        dataBinding.total.R_Jun += itm.R_Jun;
                        dataBinding.total.R_Mid += itm.R_Mid;
                        dataBinding.total.R_Sup += itm.R_Sup;
                        dataBinding.total.R_Top += itm.R_Top;
                    });


                    // 승패 데이터 가공
                    originArr.forEach((itm, idx) => {
                        // 블루팀
                        if (idx === 0) dataBinding.blueWin = itm.is_win;
                        //레드팀
                        if (idx === 5) dataBinding.redWin = itm.is_win;

                        // 팀,라인
                        let sideLane = '';
                        if (itm.side === 'BLUE') {
                            sideLane = 'B_';
                        } else if (itm.side === 'RED') {
                            sideLane = 'R_';
                        }
                        if (itm.position === 'Top') {
                            sideLane += 'Top';
                        } else if (itm.position === 'Jungle') {
                            sideLane += 'Jun';
                        } else if (itm.position === 'Middle') {
                            sideLane += 'Mid';
                        } else if (itm.position === 'Bot') {
                            sideLane += 'Bot';
                        } else if (itm.position === 'Supporter') {
                            sideLane += 'Sup';
                        }
                        itm.sideLane = sideLane;

                    });


                    setData(dataBinding);
                }
            });

        }, [chartModalInfo, originArr, selectedInfo]);


        useEffect(() => {
            initialFunc();
        }, [initialFunc]);


        const handleChampionClick = (data) => {
            setChartModalInfo((prev) => {
                return {...prev, modalIndex: 0}
            })
            setSelectedInfo(data)
        }


        const handleModalPrev = () => {
            setChartModalInfo((prev) => {
                if (prev.modalIndex > 0) {
                    return {...prev, modalIndex: prev.modalIndex - 1}
                } else {
                    alert(t('modal.alertFirst'));
                    return prev;
                }
            })
        };

        const handleModalNext = () => {
            setChartModalInfo((prev) => {
                if (prev.modalIndex < chartModalInfo.eventArr.length - 1) {
                    return {...prev, modalIndex: prev.modalIndex + 1}
                } else {
                    alert(t('modal.alertLast'));
                    return prev;
                }
            })
        };


        const handleKeyboardArrow = (e) => {
            e.keyCode === 37 && handleModalPrev();
            e.keyCode === 39 && handleModalNext();
        };

        useEffect(() => {
            document.addEventListener('keyup', handleKeyboardArrow);
            return () => {
                document.removeEventListener('keyup', handleKeyboardArrow)
            }
        }, []);


        if (Object.values(data).length < 1) return null;

        return (
            <ChartModalContainer className={modalOpen ? 'openModal' : 'closeModal'}>
                <div className="dimm" onClick={() => handleModalClose()}></div>
                <ChartModalArrow
                    handleModalNext={handleModalNext}
                    handleModalPrev={handleModalPrev}
                />
                <div className="modal" onKeyUp={handleKeyboardArrow}>
                    <div className="description">
                        <p>{t('modal.keyboard')}</p>
                    </div>
                    <div className="title">
                        <h3>
                            {t('modal.whoWillWin')}
                            <span>
                ({getFormatTime(data.minTime)} ~ {getFormatTime(data.maxTime)})
              </span>
                        </h3>
                        <div className="winrate">
                            {/* 블루팀 */}
                            <div className={`team ${data.blueWin ? 'win' : 'lose'}`}>
                                <div
                                    className={`updown ${
                                        getNeGa(data.team.win_rate_diff) === 'positive' ? 'positive' : 'negative'
                                    }`}
                                >
                                    (<i></i>
                                    {Math.abs(data?.team?.win_rate_diff?.toFixed(1))}%)
                                </div>
                                <h4>
                                    {getWinrateCalc(data.team.win_rate, 'blue').toFixed(0)}
                                    <span>%</span>
                                </h4>
                            </div>
                            {/* 우세도 */}
                            <div className="woose_bar">
                                <div
                                    className={`bar ${data.blueWin ? 'win' : 'lose'}`}
                                    style={{width: `${getWinrateCalc(data.team.win_rate, 'blue').toFixed(1)}%`}}
                                ></div>
                                <div
                                    className={`bar ${data.redWin ? 'win' : 'lose'}`}
                                    style={{width: `${getWinrateCalc(data.team.win_rate, 'red').toFixed(1)}%`}}
                                ></div>
                            </div>
                            {/* 레드팀 */}
                            <div className={`team ${data.redWin ? 'win' : 'lose'}`}>
                                <h4>
                                    {getWinrateCalc(data.team.win_rate, 'red').toFixed(0)}
                                    <span>%</span>
                                </h4>
                                <div
                                    className={`updown ${
                                        getNeGa(data.team.win_rate_diff) === 'positive' ? 'negative' : 'positive'
                                    }`}
                                >
                                    (<i></i>
                                    {Math.abs(data?.team.win_rate_diff?.toFixed(1))}%)
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="result">
                        {/* 블루팀 */}
                        <div className={`team blue ${data.blueWin ? 'win' : 'lose'}`}>
                            {data.blueWin ?
                                <TrophyAbsolute side={'blue'}><img src={'images/ico_trophy.svg'} width={18} height={19}
                                                                   alt={""}/></TrophyAbsolute> : null}
                            <h4>
                                {data.blueWin ? t('summoner.win') : t('summoner.lose')}
                                {currentLang === 'KOR' && t('summoner.team')}
                            </h4>
                            <ul>
                                {originArr.map(
                                    (item, index) =>
                                        item.side === 'BLUE' && (
                                            <li key={index + "orr"} onClick={() => handleChampionClick(item)}>

                                                <span
                                                    className={`champ ${
                                                        item.riot_id_name === selectedInfo.riot_id_name ? 'is_mine' : 'not_mine'
                                                    }`}
                                                >
                                                <ChampionImageWithLane champion_id={item.champion_id}
                                                                       level={data.level[item.sideLane]}
                                                                       disabled={true}/>


                                                </span>
                                                <span className="score">
                                            {scoreColor(data.team[item.sideLane]?.toFixed(0))}
                                    </span>
                                                {data.total[item.sideLane] !== 0 ?
                                                    <span className={`updown ${getNeGa(data.total[item.sideLane])}`}>
                                (<i></i>
                                                        {Math.abs(data.total[item.sideLane]).toFixed(1)})
                            </span>
                                                    : null}
                                            </li>
                                        )
                                )}
                            </ul>
                        </div>
                        {/* 레드팀 */}
                        <div className={`team red ${data.redWin ? 'win' : 'lose'}`}>
                            {data.redWin ?
                                <TrophyAbsolute side={'red'}><img src={'images/ico_trophy.svg'} width={18} height={19}
                                                                  alt={""}/></TrophyAbsolute> : null}
                            <h4>
                                {data.redWin ? t('summoner.win') : t('summoner.lose')}
                                {currentLang === 'KOR' && t('summoner.team')}
                            </h4>
                            <ul>
                                {originArr.map(
                                    (item) =>
                                        item.side === 'RED' && (
                                            <li key={item.puu_id + "Red"} onClick={() => handleChampionClick(item)}>
                        <span
                            className={`champ ${
                                item.riot_id_name === selectedInfo.riot_id_name ? 'is_mine' : 'not_mine'
                            }`}
                        >
                          <ChampionImageWithLane champion_id={item.champion_id} level={data.level[item.sideLane]}
                                                 disabled={true}/>

                        </span>
                                                <span className="score">
                          {scoreColor(data.team[item.sideLane]?.toFixed(0))}
                        </span>
                                                {data.total[item.sideLane] !== 0 ?
                                                    <span className={`updown ${getNeGa(data.total[item.sideLane])}`}>
                                                <span>
                          (<i></i>
                                                    {Math.abs(data.total[item.sideLane]).toFixed(1)})
                                                    </span>
                        </span> : null}
                                            </li>
                                        )
                                )}
                            </ul>
                        </div>
                    </div>
                    <div className="view">
                        <ul className="event_box">
                            {data.event.map((item, index) => {
                                return (
                                    <li
                                        className={`event_item ${
                                            item.event.side === 'BLUE'
                                                ? data.blueWin
                                                    ? 'win'
                                                    : 'lose'
                                                : data.redWin
                                                    ? 'win'
                                                    : 'lose'
                                        }`}
                                        key={index + 'event'}
                                    >

                                        {/* 이벤트 타입 종류 : Champion, Tower, Monster, Item */}
                                        <div className="event_column1">
                                            <span className="num">{index + 1}</span>
                                            <span className="time">{getFormatTime(item.time)}</span>
                                        </div>
                                        <div className="event_column2">
                                            <ul className="list">

                                                {item.event.assists.map((assist, index) => {
                                                    const user = originArr.find((data) => data.sideLane === assist);
                                                    return (
                                                        <li key={index + 'assist'}>
                                                            <div
                                                                className={`champ ${
                                                                    user.riot_id_name === selectedInfo.riot_id_name
                                                                        ? 'is_mine'
                                                                        : 'not_mine'
                                                                }`}
                                                            >
                                                                <ChampionImageWithLane
                                                                    disabled={true}
                                                                    level={data.level[user.sideLane]}
                                                                    champion_id={user.champion_id}/>

                                                            </div>
                                                            <span className={`updown ${getNeGa(item[user.sideLane])}`}>
                                                                <i></i>{Math.abs(item[user.sideLane]).toFixed(1)}
                                                        </span>
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                            {item.event.killer !== '' ? (
                                                // 이벤트 킬러가 챔피언, 타워 일때
                                                originArr.map(
                                                    (user, index) =>
                                                        user.sideLane === item.event.killer && (
                                                            <div className="killer" key={index + "eventKiller"}>
                                                                <div
                                                                    className={`champ ${
                                                                        user.riot_id_name === selectedInfo.riot_id_name ? 'is_mine' : 'not_mine'
                                                                    }`}
                                                                >
                                                                    <ChampionImageWithLane
                                                                        disabled={true}
                                                                        champion_id={user.champion_id}
                                                                        level={data.level[user.sideLane]}/>

                                                                </div>
                                                                <span className={`updown ${getNeGa(item[user.sideLane])}`}>
                                  <i></i>
                                                                    {Math.abs(item[user.sideLane]).toFixed(1)}
                                </span>
                                                            </div>
                                                        )
                                                )
                                            ) : (
                                                <div className="killer">
                                                    <div className="champ">
                                                        {'BLUE' === item.event.side ? (
                                                            <img
                                                                src="/images/icon_popup_turret_blue_square.png"
                                                                className="imgEnv"
                                                                width={'36px'}
                                                                height={'36px'}
                                                                alt="블루 타워"
                                                            />
                                                        ) : (
                                                            <img
                                                                src="/images/icon_popup_turret_red_square.png"
                                                                className="imgEnv"
                                                                width={'36px'}
                                                                height={'36px'}
                                                                alt="레드 타워"
                                                            />
                                                        )}
                                                    </div>
                                                    <span className="updown">&nbsp;</span>
                                                </div>
                                            )}
                                            {item.event.killer === 'None' && (
                                                // 이벤트 킬러가 미니언 일때
                                                <div className="killer">
                                                    <div className="champ">
                                                        {'BLUE' === item.event.side ? (
                                                            <img
                                                                src="/images/icon_popup_bluemelee_square.png"
                                                                className="imgEnv"
                                                                width={'36px'}
                                                                height={'36px'}
                                                                alt="블루 미니언"
                                                            />
                                                        ) : (
                                                            <img
                                                                src="/images/icon_popup_redmelee_square.png"
                                                                className="imgEnv"
                                                                width={'36px'}
                                                                height={'36px'}
                                                                alt="레드 미니언"
                                                            />
                                                        )}
                                                    </div>
                                                    <span className="updown">&nbsp;</span>
                                                </div>
                                            )}
                                            {/*        {item.event.type === 'Item' &&*/}
                                            {/*            // 이벤트 타입이 아이템 일때*/}
                                            {/*            originArr.map(*/}
                                            {/*                (user) =>*/}
                                            {/*                    user.sideLane === item.event.user && (*/}
                                            {/*                        <div className="killer" key={user.frameId}>*/}
                                            {/*                            <div*/}
                                            {/*                                className={`champ ${*/}
                                            {/*                                    user.riot_id_name === info.riot_id_name ? 'is_mine' : 'not_mine'*/}
                                            {/*                                }`}*/}
                                            {/*                            >*/}
                                            {/*                                <ChampionImageWithLane champion_id={user.champion_id}*/}
                                            {/*                                                       level={data.level[user.sideLane]}/>*/}

                                            {/*                            </div>*/}
                                            {/*                            <span className={`updown ${getNeGa(item[user.sideLane])}`}>*/}
                                            {/*  (<i></i>*/}
                                            {/*                                {Math.abs(item[user.sideLane]).toFixed(1)})*/}
                                            {/*</span>*/}
                                            {/*                        </div>*/}
                                            {/*                    )*/}
                                            {/*            )}*/}
                                        </div>
                                        <div className="event_column3">
                                            <div className="vs"></div>
                                        </div>
                                        <div className="event_column4">
                                            {item.event.type === 'Champion' &&
                                                originArr.map(
                                                    (user, index) =>
                                                        user.sideLane === item.event.target && (
                                                            <div className="target" key={index + 'Champion'}>
                                                                <div
                                                                    className={`champ ${
                                                                        user.riot_id_name === selectedInfo.riot_id_name ? 'is_mine' : 'not_mine'
                                                                    }`}
                                                                >
                                                                    <ChampionImageWithLane
                                                                        disabled={true}
                                                                        champion_id={user.champion_id}
                                                                        level={data.level[user.sideLane]}/>

                                                                </div>
                                                                <span className={`updown ${getNeGa(item[user.sideLane])}`}>
                                  <i></i>
                                                                    {Math.abs(item[user.sideLane]).toFixed(1)}
                                </span>
                                                            </div>
                                                        )
                                                )}
                                            {item.event.type === 'Tower' && (
                                                <div className="target">
                                                    <div className="champ">
                                                        {'BLUE' !== item.event.side ? (item.event.target.indexOf('TOWER') !== -1 ? (
                                                                <img
                                                                    src="/images/icon_popup_turret_blue_square.png"
                                                                    className="imgTower"
                                                                    alt="블루 타워"
                                                                />
                                                            ) : (
                                                                <img
                                                                    src="/images/icon_popup_inhibitor_blue_square.png"
                                                                    className="imgTower"
                                                                    alt="블루 억제기"
                                                                />
                                                            )
                                                        ) : (
                                                            item.event.target.indexOf('TOWER') !== -1 ? (
                                                                <img
                                                                    src="/images/icon_popup_turret_red_square.png"
                                                                    className="imgTower"
                                                                    alt="레드 타워"
                                                                />
                                                            ) : (
                                                                <img
                                                                    src="/images/icon_popup_inhibitor_red_square.png"
                                                                    className="imgTower"
                                                                    alt="레드 억제기"
                                                                />
                                                            )
                                                        )}
                                                    </div>
                                                    <span className="updown">&nbsp;</span>
                                                </div>
                                            )}
                                            {item.event.type === 'Monster' && (
                                                <div className="target">
                                                    <div className="champ">
                                                        <ImageMonster obj={item.event.target} noIcon={true}/>
                                                    </div>
                                                    <span className="updown">&nbsp;</span>
                                                </div>
                                            )}
                                            {item.event.type === 'Item' && (
                                                <div className="target">
                                                    <div className="champ">
                                                        <ImageMonster obj={item.event.target} noIcon={true}/>
                                                    </div>
                                                    <span className="updown">&nbsp;</span>
                                                </div>
                                            )}
                                        </div>
                                    </li>
                                );
                            })}
                        </ul>
                        <div className="map_box">
                            {/* 블루팀 win */}
                            {data.blueWin && (
                                <div>
                  <span className="mini icon" style={{left: '28px', top: '250px'}}>
                    <img src="/images/icon_blue_nexus.svg" alt="blue_nexus"/>
                  </span>
                                    <span className="mini icon" style={{left: '250px', top: '28px'}}>
                    <img src="/images/icon_red_nexus.svg" alt="red_nexus"/>
                  </span>
                                    <span
                                        className={`mini icon`} style={{
                                        left: 10,
                                        bottom: 4,
                                    }}><img src={'images/ico_trophy.svg'} width={18} height={19}
                                            alt={""}/></span>
                                    {mapLocation.map((item, index) => {


                                        const targetName = Object.keys(item)[0];
                                        const coordinate = Object.values(item)[0];


                                        return (
                                            <span
                                                className={`mini icon`}
                                                key={index + "mapLocation"}
                                                style={{
                                                    left: `${coordinate.x}px`,
                                                    top: `${coordinate.y}px`,
                                                }}
                                            >
                        {Number(data?.tower[targetName]?.state) === 1 && (
                            <Structure targetName={targetName} againstTeam="red"/>
                        )}
                      </span>
                                        );
                                    })}
                                </div>
                            )}
                            {/* 레드팀 win */}
                            {data.redWin && (
                                <div>
                  <span className="mini icon" style={{left: '28px', top: '250px'}}>
                    <img src="/images/icon_red_nexus.svg" alt="red_nexus"/>
                  </span>
                                    <span className="mini icon" style={{left: '250px', top: '28px'}}>
                    <img src="/images/icon_blue_nexus.svg" alt="blue_nexus"/>
                  </span>
                                    <span
                                        className={`mini icon`} style={{
                                        right: 3,
                                        top: 12,
                                    }}>
                                    <img src={'images/ico_trophy.svg'} width={18} height={19}
                                         alt={""}/></span>
                                    {mapLocation.map((item, index) => {
                                        const targetName = Object.keys(item)[0];
                                        const coordinate = Object.values(item)[0];
                                        return (
                                            <span
                                                className={`mini icon`}
                                                key={index + "mapLocation2"}
                                                style={{
                                                    left: `${coordinate.x}px`,
                                                    top: `${coordinate.y}px`,
                                                }}
                                            >
                        {Number(data?.tower[targetName]?.state) === 1 && (
                            <Structure targetName={targetName} againstTeam="blue"/>
                        )}
                      </span>
                                        );
                                    })}
                                </div>
                            )}
                            {data.event.map((item, index) => {

                                if (item.event.type !== 'Tower') {
                                    return (
                                        <span
                                            className={`mini num ${
                                                item.event.side === 'BLUE'
                                                    ? data.blueWin
                                                        ? 'win'
                                                        : 'lose'
                                                    : data.redWin
                                                        ? 'win'
                                                        : 'lose'
                                            }`}
                                            key={index + "eventMap"}
                                            style={{
                                                left: `${item?.event?.coordinates?.x_scaled}px`,
                                                top: `${item?.event?.coordinates?.y_scaled}px`
                                            }}
                                        >
                      {index + 1}
                    </span>
                                    );
                                } else {
                                    return (
                                        <div>
                                    <span
                                        className={`mini num ${
                                            item.event.side === 'BLUE'
                                                ? data.blueWin
                                                    ? 'win'
                                                    : 'lose'
                                                : data.redWin
                                                    ? 'win'
                                                    : 'lose'
                                        }`}
                                        key={index + "blue"}
                                        style={{
                                            left: `${item?.event?.coordinates?.x_scaled}px`,
                                            top: `${item?.event?.coordinates?.y_scaled}px`
                                        }}
                                    >
                      {index + 1}
                    </span>
                                            <span
                                                className="mini destruction"
                                                style={{
                                                    left: `${item?.event?.coordinates?.x_scaled}px`,
                                                    top: `${item?.event?.coordinates?.y_scaled}px`
                                                }}
                                            >
                      <img src="/images/icon_destruction.svg" alt="destruction"/>
                    </span>
                                        </div>
                                    );
                                }
                            })}
                        </div>
                    </div>
                    <div className="chart" key={selectedInfo.puu_id}>
                        <ChartAnalysis
                            handleModalFunction={handleModalFunction}
                            inferenceData={inferenceData}
                            info={selectedInfo}
                            originArr={originArr}
                            inModal={true}
                            inModalTime={data.team.time}
                        />
                    </div>
                </div>
            </ChartModalContainer>
        )
            ;
    }
;

const Structure = ({targetName, againstTeam}) => {


    if (targetName.toLowerCase().indexOf(againstTeam) !== -1) {
        if (targetName.toLowerCase().indexOf('tower') !== -1) {
            return <img src="/images/icon_blue_turret.svg" alt="blue_turret"/>;
        } else {
            return <img src="/images/icon_blue_inhibitor.svg" alt="blue_inhibitor"/>;
        }
    } else {
        if (targetName.toLowerCase().indexOf('tower') !== -1) {
            return <img src="/images/icon_red_turret.svg" alt="red_turret"/>;
        } else {
            return <img src="/images/icon_red_inhibitor.svg" alt="red_inhibitor"/>;
        }
    }
};

export default ChartModal;
