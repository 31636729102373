import React, {useMemo} from 'react';
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import styled, {useTheme} from "styled-components";
import {SummonerMatchBox} from "../../../ui/Box";
import {ColumnBox} from "../../../ui/styled";
import ChampionImageWithLane from "../../../common/ChampionImageWithLane";
import useChampionFunc from "../../../../common/hooks/useChampionFunc";
import {changeListBySort, kdaColor, scoreColor} from "../../../../function";
import {Link, useHistory, useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {Loading} from "../../../common";
import {dark, light} from "../../../../common/theme";


const Wrapper = styled(SummonerMatchBox)`
  width: 302px;
  background-color: ${props => props.theme.color.site_main_color};
  margin-bottom: 10px;
  overflow: hidden;
`;

const CustomTabList = styled(TabList)`
  display: flex;
  flex-direction: row;

  border-radius: 5px 5px 0 0;
  padding: 10px;
  border-bottom: 1px solid ${props => props.theme.color.default_border_color};
`;
const CustomTab = styled(Tab)`
  cursor: pointer;
  font-size: 12px;
  flex: 1;
  padding: 9px 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  border: 1.5px solid ${props => props.theme.color.default_border_color};
  border-radius: 5px;
  color: ${props => props.theme.color.dark_200};
  font-weight: 500;


  &:hover {
    background-color: ${props => props.theme.color.white_200};
  }

  & + & {
    margin-left: 5px;
  }

  &.react-tabs__tab--selected {
    background-color: ${props => props.theme.color.green_200};;
    border-color: ${props => props.theme.color.green_100};
    color: ${props => props.theme.color.green_100};
    font-weight: bold;
  }

`;

const ChampionPanelWrapper = styled.ul`

  .light + .light {
    border-top: 1px solid ${props => light.gray_200};
  }

  .dark + .dark {
    border-top: 1px solid ${props => dark.gray_200};
  }
`;


const ChampName = styled(Link)`
  font-weight: 500;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  font-size: 12px;
`;

const ScoreName = styled.span`
  font-size: 12px;
`;
const ChampNameGray = styled.span`
  font-size: 12px;
  color: ${props => props.theme.color.dark_200};
`;


const ChampionPanelItem = styled.li`
  padding: 4px 10px;
  display: flex;
  flex-direction: row;


  .imgDiv {
    border-radius: 5px;
  }

  .imgChamp {
    width: 42px;
    height: 42px;
  }

  .non_image {
    width: 26px;
    height: 26px;
  }

  .kda_color {
    font-size: 12px;
  }
`;

const MoreButton = styled.button`
  font-size: 12px;
  font-weight: 400;
  padding: 10px;
  text-align: center;
  width: 100%;
  background-color: ${props => props.theme.color.site_main_color};
  border-top: 1px solid ${props => props.theme.color.default_border_color};
  color: ${props => props.theme.color.dark_200};

  &:hover {
    background-color: ${props => props.theme.color.white_200};
  }
`;

const FLexColumn = styled(ColumnBox)`
  padding: 2px 0;
  flex: 1;
`;

const EmptyWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 273.5px;
`;
const Percent = styled.span`
  opacity: 0.7;
`;
const SummonerMatchesChampion = ({stats}) => {
    const history = useHistory();
    const {userName, region} = useParams();
    const champions = useSelector((state) => state.imageStore.champions);

    const tryCatchItem = (key) => {
        try {
            const list = (stats.counter_champion_stats[key].enemy_champion_stats.All).filter((data, index) => index !== 0);
            return changeListBySort(list, [{
                value: 'games',
                sortType: false
            }, {
                value: "wins",
                sortType: false
            }, {
                value: 'champion_name', sortType: false
            }], champions).slice(0, 6);
        } catch (e) {
            return [];
        }
    }
    const allStats = useMemo(() => tryCatchItem('total'), [stats]);
    const rankStats = useMemo(() => tryCatchItem('ranked_solo_5x5'), [stats]);
    const flexStats = useMemo(() => tryCatchItem('ranked_flex_sr'), [stats]);
    const {t} = useTranslation();

    const getSummonerInfoLoading = useSelector((state) => state.summoner.getSummonerInfoLoading);
    const getChampionsBySeasonLoading = useSelector((state) => state.summoner.getChampionsBySeasonLoading);

    if (getSummonerInfoLoading || getChampionsBySeasonLoading) {
        return (
            <Wrapper>
                <ChampionPanelWrapper>
                    <EmptyWrapper>
                        <Loading/>
                    </EmptyWrapper>
                </ChampionPanelWrapper>
            </Wrapper>
        )
    }

    if (allStats.length < 1) {
        return null;
    }

    return (
        <Wrapper>
            <Tabs>
                <CustomTabList>
                    <SummonerMatchesChampionTab>{t("tabs.total")}</SummonerMatchesChampionTab>
                    <SummonerMatchesChampionTab>{t("tabs.rankedSolo")}</SummonerMatchesChampionTab>
                    <SummonerMatchesChampionTab>{t("tabs.rankedFlex")}</SummonerMatchesChampionTab>
                </CustomTabList>
                <TabPanel>
                    <SummonerMatchesChampionPanel list={allStats}/>
                </TabPanel>
                <TabPanel>
                    <SummonerMatchesChampionPanel list={rankStats}/>
                </TabPanel>
                <TabPanel>
                    <SummonerMatchesChampionPanel list={flexStats}/>
                </TabPanel>
            </Tabs>

            <Link to={`/summoner/${region}/${userName}/champions`}>
                <MoreButton>
                    {t('summoner.moreChamp')}
                </MoreButton>
            </Link>
        </Wrapper>
    );
};


const SummonerMatchesChampionTab = ({children, ...others}) => {
    return (
        <CustomTab {...others}>
            <span>{children}</span>
        </CustomTab>
    )
}
const SummonerMatchesChampionPanel = ({list = []}) => {
    const {t} = useTranslation();
    const {getChampionName, newTabNavigateToBuildUrl, getChampion} = useChampionFunc();
    const theme = useTheme();


    const getBuildUrl = (id) => {
        const champion = getChampion(id);
        return newTabNavigateToBuildUrl(champion);
    }

    if (list.length < 1) {
        return <ChampionPanelWrapper>
            <EmptyWrapper>
                {t('notice.noData')}
            </EmptyWrapper>
        </ChampionPanelWrapper>
    }

    return (
        <ChampionPanelWrapper>
            {list.map((data, index) => {
                const buildUrl = getBuildUrl(data.champion_id)
                return (
                    <ChampionPanelItem key={index} className={theme.color.theme}>
                        <ChampionImageWithLane champion_id={data.champion_id}/>
                        <ColumnBox margin={'0 0 0 10px'}
                                   alignItems={'space-between'}
                                   height={'36px'}
                                   padding={'2px 0'}
                                   justifyContent={'space-between'}>
                            <ChampName to={buildUrl}>{(getChampionName(data.champion_id))}</ChampName>
                            {kdaColor(data.kda, ' KDA')}
                        </ColumnBox>
                        <FLexColumn height={'36px'} alignItems={'flex-end'} justifyContent={'space-between'}>
                            <ScoreName>{scoreColor(data.win_rate.toFixed(0))} <Percent>%</Percent></ScoreName>
                            <ChampNameGray>{data.games} {t('summoner.games')}</ChampNameGray>
                        </FLexColumn>
                    </ChampionPanelItem>
                )
            })}
        </ChampionPanelWrapper>
    )
}
SummonerMatchesChampionTab.tabsRole = 'Tab'
export default SummonerMatchesChampion;