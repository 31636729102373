import React, {useMemo} from 'react';
import styled from "styled-components";
import ChampionsRankingTableContent from "./ChampionsRankingTableContent";
import {useDispatch, useSelector} from "react-redux";
import ChampionRankingTableHeader from "./ChampionRankingTableHeader";
import {capitalizeLetter, sortChampData} from "../../../../../function";
import InternalServerError from "../../../../common/InternalError";
import useSortArray from "../../../../../common/hooks/useSortArray";
import {useSuspenseQuery} from "@tanstack/react-query";
import axios from "axios";
import {GET_CHAMPIONS_RANK_FAILURE, GET_CHAMPIONS_RANK_SUCCESS} from "../../../../../store/reducer/champions";
import useGetURLTierVersion from "../../../../../common/hooks/useGetURLTierVersion";


const Wrapper = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const TBody = styled.tbody`

`;

const initSort = [{
    value: 'rank',
    sortType: false,
    reverseSort: true,
}];

const initSortList = {
    rank: {
        value: 'rank',
        sortType: false,
        reverseSort: true,
    },
    tier: {
        value: 'tier',
        sortType: true,
        reverseSort: true,
    },
    ai_score: {
        value: 'ai_score',
        sortType: true,
    },
    win_rate: {
        value: 'win_rate',
        sortType: true,
    },
    pick_rate: {
        value: 'pick_rate',
        sortType: true,
    },
    ban_rate: {
        value: 'ban_rate',
        sortType: true,
    },
}

const getRankData = (action) => {
    if (typeof action === 'object') {
        const url = `${process.env.REACT_APP_CACHE_API}champion/rank?platform_id=KR&tier=${encodeURIComponent(action.tier)}&game_version=${action.version}`;
        return axios.get(url).then((result) => result.data);
    }
}

const ChampionsRankingTable = ({  pageValue = 99}) => {
    const dispatch = useDispatch();
    const version = useSelector((state) => state.gameVersion.version);
    const championRanks = useSelector((state) => state?.champions?.ranks);
    const rankingLane = useSelector((state) => state?.champions?.prevRankingLane);
    const versions = useSelector((state) => state.champions.championVersions);

    const {sort, sortList, onClickSort} = useSortArray({
        initSort: initSort,
        initSortList: initSortList,
    });

    const {currentVersion,currentTier} = useGetURLTierVersion();


    const ranks = useMemo(() => {
        try {
            return championRanks[rankingLane.toLowerCase()]
        } catch (e) {
            return [];
        }
    }, [rankingLane, championRanks])

    const suspenseQuery = useSuspenseQuery({
        queryKey: ['championsRank', currentTier, currentVersion],
        queryFn: async () => {

            try {
                const dataObj = {
                    version: currentVersion,
                    tier: currentTier,
                }

                const getData = await getRankData(dataObj)

                dispatch({
                    type: GET_CHAMPIONS_RANK_SUCCESS,
                    data: getData,
                })

                return getData
            } catch (e) {
                dispatch({
                    type: GET_CHAMPIONS_RANK_FAILURE,
                    error: e,
                })
                return []
            }
        },
    })


    const rankMemo = useMemo(() => {
        const currentData = ranks.map((mapData) => ({
            ...mapData?.performance_dict[capitalizeLetter(rankingLane)],
            champion_id: mapData.champion_id,
        }));
        return sortChampData(currentData, sort);
    }, [sort, ranks, rankingLane]);


    if (suspenseQuery.isError) return (
        <InternalServerError/>
    );

    if (suspenseQuery.isSuccess && suspenseQuery?.data?.length === 0) {
        return <InternalServerError errorText={"There are no data"}/>
    }


    return (
        <Wrapper>
            <colgroup>
                <col width={'12%'}/>
                <col/>
                <col width={'15%'}/>
                <col width={'15%'}/>
                <col width={'15%'}/>
                <col width={'15%'}/>
                <col width={'15%'}/>
            </colgroup>
            <thead>
            <ChampionRankingTableHeader
                currentSort={sort}
                onClickSort={onClickSort}
                sortData={sortList}
            />
            </thead>
            <TBody>
                {Array.isArray(ranks) && rankMemo.slice(0, pageValue).map((data, index) => {
                    return (
                        <ChampionsRankingTableContent
                            lane={rankingLane}
                            tier={data.tier}
                            rankDelta={data.rank_delta}
                            champion_id={data.champion_id}
                            pickRate={data?.pick_rate}
                            winRate={data?.win_rate}
                            banRate={data?.ban_rate}
                            aiScore={data?.ai_score}
                            key={data.rank + rankingLane + data.champion_id}
                            counter={data?.counter || []}
                            rank={data.rank}
                            version={version}
                        />
                    )
                })}
            </TBody>
        </Wrapper>
    );
};


export default ChampionsRankingTable;
