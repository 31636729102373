import React, {useEffect, useMemo, useState} from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import {useTranslation} from 'react-i18next';
import _, {isNaN} from 'lodash';
import ChartModal from './ChartModal';
import styled, {useTheme} from "styled-components";
import {ImageMonster} from "../common";
import {AI_SCORE_BAD_TEXT} from "../../styled/scoreColor";
import useDetailChart from "../../common/hooks/useDetailChart";


const WinLoseSpan = styled.div`
  font-weight: bold;
  position: absolute;
  display: flex;
  top: 50%;
  transform: translateY(-50%);
  flex-direction: row;
  align-items: center;
  font-size: 12px;
  left: 0px;
  color: ${props => {
    let color = props.theme.color.red_100
    if (props.isWin) color = props.theme.color.blue_100;
    return color;
  }};
`;

const TeamSpan = styled.div`
  font-weight: normal;
  margin-left: 4px;
  font-size: 11px;
  color: ${props => props.theme.color.dark_200};
  opacity: 0.8;
`;


const ChartAnalysis = ({
                           inferenceData, info, originArr, inModal, inModalTime, handleModalFunction = () => {
    }, withOpen = false
                       }) => {
    const {t} = useTranslation();
    const [blueMonsters, setBlueMonsters] = useState([]);
    const [redMonsters, setRedMonsters] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalData, setModalData] = useState({});
    const theme = useTheme();
    const {aiScoreArr, eventArr, concatEvent, detailEvent, originalEvent} = useDetailChart({inferenceData, info})

    const handleModalClose = () => {
        setModalOpen(false);
    };


    useEffect(() => {
        if (withOpen) {
            handleModalFunction({
                modalIndex: 0,
                eventArr,
                originalEvent,
                detailEvent,
                concatEvent,
                aiScoreArr
            });
            setModalData({
                modalIndex: 0,
                eventArr,
                originalEvent,
                detailEvent,
                concatEvent,
                aiScoreArr
            });
            setModalOpen(true)
        }
    }, [withOpen]);

    // 포지션 찾기
    let side = info.side === 'BLUE' ? 'B_' : 'R_';

    let lane = '';
    switch (info.position) {
        case 'Top':
            lane = 'Top';
            break;
        case 'Jungle':
            lane = 'Jun';
            break;
        case 'Middle':
            lane = 'Mid';
            break;
        case 'Bot':
            lane = 'Bot';
            break;
        case 'Supporter':
            lane = 'Sup';
            break;
        default:
            break;
    }

    const teamSide = info.side === 'BLUE' ? 'B_Avg' : 'R_Avg';

    const position = side + lane;


    const eventList = useMemo(() => {
        const baseList = inferenceData?.concat_participants_dict[position];
        const copyArray = [...baseList];
        concatEvent.forEach((data, index) => {
            const allEvent = data.find((item) => {
                return item.event.type === 'Monster' || item.event.type === 'Tower'
            });
            if (allEvent) {
                copyArray.push(index);
            }
        });
        return _.uniq(copyArray);
    }, [inferenceData, concatEvent]);

    const personalEventMemo = useMemo(() => concatEvent, [concatEvent])
    const personalScoreMemo = useMemo(() => aiScoreArr, [aiScoreArr]);

    const individualScore = useMemo(() => {
        if (Array.isArray(personalEventMemo) && personalEventMemo.length > 0) {
            return personalEventMemo.reduce((sum, cur, index) => {
                const lastData = cur[0];
                if(!personalScoreMemo?.[index]?.[position]) {
                    return sum;
                }
                sum.push({
                    x: lastData.time,
                    y: personalScoreMemo[index][position],
                })

                return sum
            }, [{x: 0, y: 50}]);
        }
        return [];
    }, [personalEventMemo, position]);




    const xAxisArr = Object.values(inferenceData.concat_events_dict).map((mapData) => {
        const array = Object.values(mapData);

        return array[0].time;
    });
    const scoreArr = Object.values(inferenceData.concat_seq_dict).map((data) => {
        return data[position]
    });

    const dataObj = xAxisArr.map((x, index) => {
        return {
            x: x, y: scoreArr[index]
        }
    });


    const spreadconcat = Object.values(inferenceData.concat_events_dict).map((mapData) => {
        const array = Object.values(mapData);
        return array.filter((data, index) => index < array.length - 2);
    });
    let spreadEvents = [];
    spreadconcat.forEach((data) => {
        spreadEvents = [...spreadEvents, ...data]
    })

    // const eventArr = inferenceData?.team_lane_index_dict[position];
    // const detailEvent = inferenceData?.detail_event;
    // const concatEvent = inferenceData?.concat_event;


    // const concatEvent = inferenceData?.concat_events_dict;


    let xAxisTeamArr = '';
    let teamArr = [];
    xAxisTeamArr = [...xAxisArr];
    teamArr = Object.values(inferenceData.concat_seq_dict).map((data) => data[teamSide]);

    // x,y 값
    let maxTime = detailEvent[Object.keys(detailEvent).length - 1]
        ? Math.floor(detailEvent[Object.keys(detailEvent).length - 1].time) + 5
        : 15;
    let timeArr = []; // x좌표

    var teamObj = [{x: 0, y: 50}]; // y좌표 팀평균 데이터

    for (let i = 0; i < maxTime; i++) {
        i % 5 === 0 && timeArr.push(i);
    }


    xAxisTeamArr.forEach((item, index) => {

        // 팀평균 데이터
        teamObj.push({
            x: xAxisTeamArr[index],
            y: teamArr[index],
        });
    });

    // ai-score 최소, 최대값 구하기
    let max = 50;
    let min = 50;
    let cValue = 0;
    for (let i = 0; i < scoreArr.length; i++) {
        if (typeof scoreArr[i] == 'object') {
            if (scoreArr[i].y >= max) {
                max = scoreArr[i].y;
            }
            if (scoreArr[i].y <= min) {
                min = scoreArr[i].y;
            }
        } else {
            if (scoreArr[i] >= max) {
                max = scoreArr[i];
            }
            if (scoreArr[i] <= min) {
                min = scoreArr[i];
            }
        }
    }
    if (max - 50 >= 50 - min) {
        max = max + 5;
        min = 100 - max;
    } else {
        min = min - 5;
        if (min <= 0) {
            min = 0;
        }
        max = 100 - min;
    }
    max = Math.floor(max);

    if (max % 5 !== 0) {
        max = max + (5 - (max % 5));
    }
    min = Math.round(min);
    min = min - (min % 5);
    cValue = (max - min) / 2 + min;

    console.log(max, min)

    const options = {
        chart: {
            type: 'line',
            plotBackgroundColor: theme.color.white_201,
            // plotBorderWidth: 1,
            // plotBorderColor: '#A5BED2',
            backgroundColor: 'none',
            height: inModal ? 300 : 377,
            marginTop: 20,
            marginRight: 30,
            events: {
                load: function () {
                    const updateObj = {
                        marker: {
                            radius: 12,
                            width: 12,
                            fillColor: null,
                            lineWidth: 0,
                        },
                        dataLabels: {
                            enabled: false,
                        },
                    };
                    const displayNone = {
                        marker: {
                            enabled: false,
                        },
                        dataLabels: {
                            enabled: false,
                        },

                    }

                    const point = this.series[0].points;
                    const allData = this.series[2].points;


                    let scoreArr = [];
                    point.forEach((p) => {
                        scoreArr.push(p.y);
                    });


                    const alreadyHas = {
                        min: false,
                        max: false,
                    }
                    point.forEach((p, idx) => {
                        const thisValue = p.y; //현재점수
                        const lastValue = p.series.yData[p.series.yData.length - 1]; //최종점수
                        const maxValue = Math.max.apply(null, scoreArr); // 큰점수
                        const minValue = Math.min.apply(null, scoreArr); // 작은점수

                        if (idx === 0) {
                            p.update(displayNone);
                        }
                        // if (thisValue === maxValue && maxValue !== 50 && !alreadyHas.max) {
                        //     p.update(updateObj);
                        //     alreadyHas.max = true;
                        // } else if (thisValue === minValue && minValue !== 50 && !alreadyHas.min) {
                        //     p.update(updateObj);
                        //     alreadyHas.min = true;
                        // } else if (thisValue === lastValue) {
                        //     p.update(updateObj);
                        // }

                    });
                    allData.forEach((p, idx) => {
                        const lastTime = p.series.xAxis.dataMax;


                        if (inferenceData) {
                            const object = Object.values(inferenceData?.concat_events_dict)[idx];
                            if (typeof object === 'object') {
                                const dataEntries = Object.entries(object);
                                dataEntries.forEach((entry) => {
                                    if (isNaN(Number(entry[0]))) {
                                        return true;
                                    }

                                    const event = entry[1]?.event;


                                    if (event.type !== 'Monster') {
                                        return true;
                                    }

                                    const target = event.target;


                                    if (event.side === 'BLUE') {
                                        setBlueMonsters(prev => {


                                            const prevX = prev[prev.length - 1]?.x;

                                            if (!prevX || p.x > prevX + 0.8) {
                                                return ([...prev, {
                                                    name: target, x: p.x, gameTime: lastTime,
                                                }])
                                            } else if (p.x >= lastTime) {
                                                return ([...prev, {
                                                    name: target, x: p.x, gameTime: lastTime,
                                                }])
                                            } else {
                                                return ([...prev, {
                                                    name: target, x: p.x + 0.8, gameTime: lastTime,
                                                }])
                                            }

                                        });
                                    } else if (event.side === 'RED') {
                                        setRedMonsters(prev => {
                                            const prevX = prev[prev.length - 1]?.x;


                                            if (!prevX || p.x > prevX + 0.8) {
                                                return ([...prev, {
                                                    name: target, x: p.x, gameTime: lastTime,
                                                }])
                                            } else if (p.x >= lastTime) {
                                                return ([...prev, {
                                                    name: target, x: lastTime, gameTime: lastTime,
                                                }])
                                            } else {
                                                return ([...prev, {
                                                    name: target, x: p.x + 0.8, gameTime: lastTime,
                                                }])
                                            }
                                        });
                                    }

                                })
                            }
                        }
                    })
                },

            },
        },
        credits: {
            enabled: false,
        },
        title: {
            text: '',
        },
        subtitle: {
            text: '',
        },
        legend: {},
        xAxis: {

            title: {
                enabled: false,
            },
            labels: {
                enabled: true,
                style: {
                    fontFamily: 'Apple SD Gothic Neo',
                    fontSize: '11px',
                    color: theme.color.dark_200,
                },
                useHTML: true,
                formatter: function () {
                    return `<span>${this.value}${t('summoner.minute')}</span>`;
                },
            },
            tickWidth: 0,
            lineColor: theme.color.default_border_color,
            lineWidth: 2,
            gridLineWidth: 1,
            gridLineColor: theme.color.default_border_color_op1,
            // gridLineColor:'red',
            // tickmarkPlacement: "on",
            tickPositions: timeArr, // 보여지는 x축 값
            plotLines: [
                {
                    color: 'rgba(13, 255, 126, 0.3)',
                    width: 13,
                    value: inModalTime,
                    label: {
                        useHTML: true,
                        textAlign: 'center',
                        verticalAlign: 'bottom',
                        x: -8,
                        y: -1,
                        formatter: function () {
                            return `<span class="plot_lines"></span>`;
                        },
                    },
                },
            ],
        },
        yAxis: [
            {
                gridLineWidth: 0,
                minorGridLineWidth: 0,
                title: {
                    enabled: false,
                },
                opposite: false,
                plotLines: [
                    {
                        color: 'rgba(248, 156, 50, 0.6)',
                        width: 2,
                        value: 75,
                    },
                    {
                        color: 'rgba(83, 149, 120, 0.6)',
                        width: 2,
                        value: 50,
                    },
                    {
                        color: 'rgba(199, 68, 68, 0.6)',
                        width: 2,
                        value: 25,
                    },
                ],
                tickInterval: cValue,
                tickPositions: [min, 25, 50, 75, max],
                lineColor: theme.color.default_border_color,
                lineWidth: 2,
                labels: {
                    enabled: true,
                    style: {
                        color: theme.color.dark_100,
                    }
                }
            },
            {
                gridLineWidth: 0,
                minorGridLineWidth: 0,
                title: {
                    enabled: false,
                },
                labels: {
                    enabled: false,
                },
                opposite: true,
                tickInterval: cValue,
                tickPositions: [min, 25, 50, 75, max],
                plotBands: [
                    {
                        id: 'verybad',
                        from: 0,
                        to: 25,
                        color: 'transparent',
                        label: {
                            useHTML: true,
                            text: t('summoner.aiscore.bad'),
                            align: 'right',
                            rotation: 90,
                            x: 6,
                            style: {
                                fontFamily: 'Apple SD Gothic Neo',
                                fontSize: '12px',
                                color: theme.color.dark_200,
                            },
                        },
                    },
                    {
                        id: 'bad',
                        from: 25,
                        to: 50,
                        color: 'transparent',
                        label: {
                            useHTML: true,
                            text: t('summoner.aiscore.fair'),
                            align: 'right',
                            rotation: 90,
                            x: 6,
                            style: {
                                fontFamily: 'Apple SD Gothic Neo',
                                fontSize: '12px',
                                color: theme.color.dark_200,
                            },
                        },
                    },
                    {
                        id: 'good',
                        from: 50,
                        to: 75,
                        color: 'transparent',
                        label: {
                            useHTML: true,
                            text: t('summoner.aiscore.good'),
                            align: 'right',
                            rotation: 90,
                            x: 6,
                            style: {
                                fontFamily: 'Apple SD Gothic Neo',
                                fontSize: '12px',
                                color: theme.color.dark_200,
                            },
                        },
                    },
                    {
                        id: 'verygood',
                        from: 75,
                        to: 999,
                        color: 'transparent',
                        label: {
                            useHTML: true,
                            text: t('summoner.aiscore.carry'),
                            align: 'right',
                            rotation: 90,
                            x: 6,
                            style: {
                                fontFamily: 'Apple SD Gothic Neo',
                                fontSize: '12px',
                                color: theme.color.dark_200,
                            },
                        },
                    },
                ],
            },
        ],
        plotOptions: {
            series: {
                animation: true,
                showInLegend: false,
                lineWidth: 4,

                marker: {
                    enabled: true,
                    radius: 6,
                    width: 6,
                    fillColor: null,
                    lineWidth: 2,
                    lineColor: '#ffffff',
                    enabledThreshold: 0,
                },
                dataLabels: {
                    enabled: false,
                },
                states: {
                    inactive: {
                        enabled: false,
                    },
                },
                shadow: true,
            },
        },
        series: [
            {
                type: 'line',
                name: 'detail chart',
                data: individualScore,
                events: {
                    mouseOver: function (e) {
                        this.xAxis.update({
                            crosshair: {
                                width: 1,
                                color: theme.color.dark_200,
                            }
                        })
                    },
                    mouseOut: function (e) {
                        this.xAxis.update({
                            crosshair: {
                                width: 0,
                                color: theme.color.dark_200,
                            }
                        })
                    }
                },
                zones: [
                    {
                        value: 25,
                        color: theme.color.gray_400,
                    },
                    {
                        value: 50,
                        color: AI_SCORE_BAD_TEXT,
                    },
                    {
                        value: 75,
                        color: theme.color.green_300,
                    },
                    {
                        color: theme.color.yellow_100,
                    },
                ],
                shadow: true,
                cursor: 'pointer',
                point: {
                    events: {
                        click: function (el) {
                            const modalIndex = el.point.index;

                            if (modalIndex !== 0) {
                                handleModalFunction({
                                    modalIndex: modalIndex - 1,
                                    eventArr,
                                    originalEvent,
                                    detailEvent,
                                    concatEvent,
                                    aiScoreArr
                                });
                                setModalData({
                                    modalIndex: modalIndex - 1,
                                    eventArr,
                                    originalEvent,
                                    detailEvent,
                                    concatEvent,
                                    aiScoreArr
                                });
                            }
                            if (modalIndex !== 0 && !inModal) {
                                setModalOpen(true);
                            }
                        },
                    },

                },
                zIndex: 10,
            },
            // 팀 평균 그래프
            {
                type: 'line',
                name: 'detail team chart',
                data: teamObj,
                shadow: false,
                animation: false,
                showInLegend: false,
                lineWidth: 2,
                lineColor: '#A878D8',
                dashStyle: 'Dash',
                marker: {
                    enabled: false,
                    states: {
                        hover: {
                            enabled: false,
                        },
                    },
                },
                dataLabels: {
                    enabled: false,
                },
                zIndex: 5,
            },
            //ALL INFORMATION
            {
                type: 'line',
                name: 'allData',
                data: dataObj,
                visible: false,
                marker: {
                    enabled: false,
                    states: {
                        hover: {
                            enabled: false,
                        },
                    },
                },
            }
        ],
        tooltip: {
            enabled: true,
            useHTML: true,
            animation: false,
            backgroundColor: null,
            borderWidth: 0,

            formatter: function () {
                let result = '';
                if (this.series.name === 'detail chart') {
                    let thisValue = this.y;
                    let prevValue = this.series.yData[this.point.index - 1];

                    let diffValue = 0;
                    if (prevValue) {
                        if (thisValue < prevValue) {
                            diffValue = prevValue - thisValue;
                            if (this.y >= 50) {
                                result = `<div class="chart_dot good">
                <span class="score">${thisValue.toFixed(1)}</span>
                <span class="diff">(<i class="negative">${diffValue.toFixed(1)}</i>)</span>
              </div>`;
                            } else {
                                result = `<div class="chart_dot bad">
                <span class="score">${thisValue.toFixed(1)}</span>
                <span class="diff">(<i class="negative">${diffValue.toFixed(1)}</i>)</span>
              </div>`;
                            }
                        } else {
                            diffValue = thisValue - prevValue;
                            if (this.y >= 50) {
                                result = `<div class="chart_dot good">
                <span class="score">${thisValue.toFixed(1)}</span>
                <span class="diff">(<i class="positive">${diffValue.toFixed(1)}</i>)</span>
              </div>`;
                            } else {
                                result = `<div class="chart_dot bad">
                <span class="score">${thisValue.toFixed(1)}</span>
                <span class="diff">(<i class="positive">${diffValue.toFixed(1)}</i>)</span>
              </div>`;
                            }
                        }
                    } else {
                        if (this.y >= 50) {
                            result = `<div class="chart_dot good one_line">
              <span class="score">${thisValue.toFixed(1)}</span>
            </div>`;
                        } else {
                            result = `<div class="chart_dot bad one_line">
              <span class="score">${thisValue.toFixed(1)}</span>
            </div>`;
                        }
                    }
                }
                return result;
            },
        },
    };


    // useEffect(() => {
    //     if (modalOpen) {
    //         const root = document.getElementById('root');
    //         root.style.cssText = `
    //     position: fixed;
    //     top: -${window.scrollY}px;
    //     overflow-y: scroll;
    //     width: 100%;
    //   `;
    //         document.body.style.cssText = `overflow-y: hidden;`;
    //         return () => {
    //             const scrollY = root.style.top;
    //             root.style.cssText = '';
    //             document.body.style.cssText = ``;
    //             window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
    //         };
    //     }
    // }, [modalOpen]);

    return (
        <div>
            <HighchartsReact highcharts={Highcharts} options={options}/>
            <div className="timeline">
                <div className={`team ${originArr[0].is_win ? 'win' : 'lose'}`}>
                    <div className="cade">
                        <div className="deca">
                            <WinLoseSpan
                                isWin={originArr[0].is_win}>{originArr[0].is_win ? t('summoner.side.winTeam') : t('summoner.side.loseTeam')}<TeamSpan>({t('summoner.side.blueTeam')})</TeamSpan></WinLoseSpan>
                            {blueMonsters.length !== 0 &&
                                blueMonsters.map((monster, index) => {
                                    return (
                                        <span
                                            key={index + 'blue'}
                                            className="monster"
                                            style={{left: `${monster.x / monster.gameTime * 100}%`}}
                                        >
                                            <ImageMonster obj={monster.name}/>
                  </span>
                                    )
                                })}
                        </div>
                    </div>

                </div>
                <div className={`team ${originArr[5].is_win ? 'win' : 'lose'}`}>
                    <div className="cade">
                        <div className="deca">
                            <WinLoseSpan
                                isWin={originArr[5].is_win}>{originArr[5].is_win ? t('summoner.side.winTeam') : t('summoner.side.loseTeam')}<TeamSpan>({t('summoner.side.redTeam')})</TeamSpan></WinLoseSpan>
                            {redMonsters.length !== 0 &&
                                redMonsters.map((monster, index) => (
                                    <span
                                        key={index + 'red'}
                                        className="monster"
                                        style={{left: `${monster.x / monster.gameTime * 100}%`}}
                                    >

                                        <ImageMonster obj={monster.name}/>
                  </span>
                                ))}
                        </div>
                    </div>
                </div>
            </div>

            <div className="legend">
                {t('summoner.team')} {t('summoner.avg')} AI-Score <i></i>
            </div>
            {modalOpen && (
                <ChartModal
                    modalData={modalData}
                    modalOpen={modalOpen}
                    handleModalClose={handleModalClose}
                    info={info}
                    originArr={originArr}
                    inferenceData={inferenceData}
                />
            )}
        </div>
    );
};
export default ChartAnalysis;
