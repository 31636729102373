import React from 'react';
import {useSelector} from "react-redux";

const ProfileIcon = ({iconId = 0, width, height}) => {
    const version = useSelector((state) => state.gameVersion.version);

    return (
        <img src={`${process.env.REACT_APP_IMG}img/profileicon/${iconId}__56.webp`} alt={''}
             onError={(e) => e.target.src = "/images/icon_non_item.svg"}
             width={width} height={height} className={'imgIcon'}/>
    );
};

export default ProfileIcon;