import React, {useMemo} from 'react';
import {sortChampData} from "../../function";

const UseDetailChartTab = ({match, myMatch}) => {
    const originArr = useMemo(() => {
        try {
            return sortChampData(match.participants_list, [{value: "side", sortType: true}])
        } catch (e) {
            return []
        }
    }, [match]);

    const redTeam = useMemo(() => {
        try {
            return originArr.filter((data) => data.side === 'RED');
        } catch (e) {
            return [];
        }
    }, [originArr]);
    const blueTeam = useMemo(() => {
        try {
            return originArr.filter((data) => data.side === 'BLUE');
        } catch (e) {
            return [];
        }
    }, [originArr]);

    const defaultTabIndex = useMemo(() => {
        if (!originArr) {
            return 0
        }

        return originArr.findIndex((data) => data?.puu_id === myMatch?.puu_id);


    }, [originArr]);


    const ratingAI = useMemo(() => {
        try {
            const matchArr = match.participants_list;
            if (Array.isArray(matchArr)) {
                // AI스코어순 정렬
                return sortChampData(matchArr, {value: "ai_score", sortType: false});
            }
            return [];
        } catch (e) {

            return [];
        }
    }, [match]);


    return {
        originArr,
        blueTeam,
        redTeam,
        defaultTabIndex,
        ratingAI
    }
};

export default UseDetailChartTab;