import React, {useMemo} from 'react';
import {useSelector} from "react-redux";
import {useQuery, useQueryClient, useSuspenseQuery} from "@tanstack/react-query";
import axios from "axios";
import {getRegion, openStoryRegion, sortChampData} from "../../../function";

const listByObjectKey = ({list, key, id}) => {
    if (!Array.isArray(list)) {
        return [];
    }
    const filtered = list?.filter((data) => data?.hasOwnProperty(key));

    if (filtered.length === 0) {
        return [];
    }
    const array = filtered.map((data) => {
        console.log(data)
        return {
            ...data[key], description: id,
            riot_name: data[key]?.riot_id_name,
            riot_tag: data[key]?.riot_id_tag_line,
        }
    })

    return array;
}
const normalizeQuery = ({result}) => {
    const description_list = result?.data?.description_list ?? [];
    const special_list = result?.data?.special_list ?? [];
    const aiList = listByObjectKey({list: special_list, key: 'ai_score', id: 1000});
    const combinedList = [...description_list, ...aiList];
    const sortedList = sortChampData(combinedList, [{value: "start_timestamp", sortType: false}])
    return sortedList
}

const langTrans = (lang) => {
    const upperLang = lang.toUpperCase()
    if(upperLang === 'KOR') {
        return 'kr'
    } else if(upperLang === 'JA') {
        return 'jp'
    }
    return 'en'
}
const queryFn = async ({region, limit, puu_id, version,lang}) => {
    try {
        const url = new URL(`${process.env.REACT_APP_CACHE_API}summoner/strm_pro_description`);
        url.searchParams.set('platform_id', getRegion(region))
        url.searchParams.set('limit', limit ? limit : 'all')
        url.searchParams.set('puu_id', puu_id ? puu_id : "")
        url.searchParams.set('version', version)
        url.searchParams.set('language', langTrans(lang))
        const result = await axios.get(url.toString());
        const normalizedList = normalizeQuery({result});
        return normalizedList;
    } catch (e) {
        return [];
    }
}

const getSingleMatch = (action) => {
    const changeRegion = getRegion(action.region);
    const url = `${process.env.REACT_APP_CACHE_API}match/match-cached?match_id=${action.match_id}&platform_id=${changeRegion}`;
    return axios.get(url).then((res) => res.data).catch((error) => console.log(error));
}


export const useStoryApi = ({limit, puu_id, status}) => {
    const region = useSelector((state) => state.region.result);
    const version = useSelector((state) => state.champions.championVersions);
    const queryClient = useQueryClient();
    const currentLang = useSelector((state) => state?.translation.lang);

    const query = useQuery({
        queryKey: [puu_id, region, 'story', status, version[0],currentLang],
        queryFn: async () => {
            if (!status) {
                return {list: []}
            }
            if (!puu_id) {
                return {list: []}
            }
            if (!openStoryRegion(region)) {
                return {list: []}
            }
            const marquee = await queryFn({puu_id, limit, region, version: version[0],lang:currentLang});
            // const matchIdList = marquee.map((data) => data.match_id);
            // const matchList = await Promise.all(matchIdList.map((item) => {
            //     const queryData = queryClient.getQueryData(['match', item]);
            //     if (queryData) {
            //         return queryData;
            //     }
            //     if (item) {
            //         const match = getSingleMatch({match_id: item, region: region}).then((res) => {
            //             queryClient.setQueryData(['match',item],res)
            //             return res;
            //         })
            //         return match
            //     }
            //     return null
            // // }));
            // const combinedList = marquee.map((data, index) => {
            //     return {
            //         ...data,
            //         match: matchList[index]
            //     }
            // })
            // const filterNullMatchList = matchList.filter((data) => data !== null);
            return {list: marquee}
        },
    })


    const matchList = useMemo(() => {
        const list = query?.data?.list ?? [];

        return {
            list
        }
    }, [query.data])

    return {matchList};
};


export const useSuspenseMarqueeApi = ({limit, puu_id}) => {
    const region = useSelector((state) => state.region.result);
    const version = useSelector((state) => state.champions.championVersions);
    const currentLang = useSelector((state) => state?.translation.lang);

    const query = useSuspenseQuery(
        {
            queryKey: ['marquee', region, limit ? String(limit) : 'all', puu_id ? puu_id : "", version[0],currentLang],

            queryFn: async () => {
                if(!openStoryRegion(region)) {
                    return [];
                }
                return await queryFn({puu_id, limit, region, version: version[0],lang:currentLang})
            },
            staleTime: Infinity
        })


    const list = useMemo(() => {
        if (!query?.data) {
            return []
        }
        return query.data
    }, [query.data])

    return {query, list};
}
