import React, {useCallback} from 'react';
import styled from "styled-components";
import {Link} from "react-router-dom";

const Wrapper = styled.ul`
  cursor: pointer;
  position: relative;
  display: ${props => {
    let display = 'flex';
    if (!props.visible) display = 'none';
    return display;
  }};
  flex-direction: column;
  align-items: center;
  margin: 5px 9px 5px 0;

  &:hover {
    scale: 1.2;
    transition: scale .2s ease-in-out;
  }
`;

const ChampionImage = styled.img`
  margin: -3px;
`;

const ImageText = styled.span`
  margin-top: 3px;
  font-size: 12px;
  text-align: center;
  height: 14px;
  width: 48px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const ImageWrapper = styled.div`
  position: relative;
`;

const ChampImageBox = styled.div`
  overflow: hidden;
  border-radius: 4px;
`;

const FreeAbsolute = styled.div`
  position: absolute;
  top: -4px;
  left: -4px;
  z-index: 9;
`;

const FreeImage = styled.img`

`;

const ChampionsChampionImage = ({
                                    koChampName,
                                    enChampName,
                                    champName,
                                    imageName,
                                    version,
                                    lang,
                                    visible = true,
                                    isFree,
                                    lane,
                                    onClickChamp
                                }) => {

    const champUrl = useCallback(() => {
        if (typeof enChampName === 'string' && typeof lane === 'string') {
            const champName = enChampName.replace(/\s|&|willump|!|/gi, "").toLowerCase();
            return onClickChamp(champName);
        }
    }, [lane, onClickChamp]);


    const champText = () => {
        if (lang === 'ja') {
            return champName
        }
        if (lang === 'KOR') {
            return koChampName
        }
        return enChampName
    }

    return (
        <Link to={champUrl()}>
                <Wrapper visible={visible}>
                    <ImageWrapper>
                        {isFree &&
                            <FreeAbsolute>
                                <FreeImage
                                    width={24}
                                    height={24}
                                    src="/images/icn_rotation_square.svg"/>
                            </FreeAbsolute>
                        }
                        <ChampImageBox>
                            <ChampionImage
                                width={48}
                                height={48}
                                src={`${process.env.REACT_APP_IMG}${version}/img/champion/${imageName}__64.webp`}
                                onError={(e) => e.target.src = "/images/icon_non_item.svg"}
                            />
                        </ChampImageBox>
                    </ImageWrapper>
                    <ImageText>{champText()}</ImageText>
                </Wrapper>
        </Link>
    );
};

export default React.memo(ChampionsChampionImage);
