import React, {useMemo} from 'react';
import styled from "styled-components";
import ChampionImageWithLane from "../../common/ChampionImageWithLane";
import SmallInfluInfo from "../../common/SmallInfluInfo";
import {FlexBox} from "../../ui/styled";
import BigTooltip from "../../Tooltip/BigTooltipBox";
import {sortChampData} from "../../../function";
import {useTranslation} from "react-i18next";
import ToSummonerLink from "../../Link/ToSummonerLink";


const SummonerBox = styled.div`
  width: 123px;
  display: flex;
  flex-direction: column;

  &:nth-child(even) {
    margin: 7px 0 11px 0;
  }

  &:nth-child(n+3) {
    margin-left: 14px;
  }
;

`;
const ArenaWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex-wrap: wrap;


  p {
    width: 90px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: break-word;
    text-align: left;
    color: ${props => props.theme.color.font_58_CE};
    line-height: 16px;
    opacity: 1;

    &:hover {
      color: ${props => props.theme.color.font_000};
      text-decoration: underline;
    }
  }

  .my {
    span {
      color: ${props => props.theme.color.dark_100};
      font-weight: bold;
      opacity: 1;
    }

    .imgChamp {
      border-radius: 50%;
    }
  }

  .myTogether {
    span {
      color: #6A5EDC;
      opacity: 1;
    }

    .imgChamp {
      border-radius: 50%;
    }
  }

  .together {
    span {
      color: #6A5EDC;
      opacity: 1;
    }
  }
}

.imgChamp {
  width: 22px;

`;


const ImageBox = styled.div`
  .imgDiv {
    border-radius: ${props => props.borderRadius};
  }
`;
const PlayerMarginBox = styled.div`
  margin-right: 4px;
`;

const SmallSummonerArenaList = ({match, getName, getPClassName, region, myMatch, hide}) => {
    const {t} = useTranslation();

    const list = useMemo(() => {
        try {
            const sortList = sortChampData(match.match_basic_dict.arena_team, [{value: "placement", sortType: true}]);

            if (myMatch.arena_team.placement > 4) {
                return sortList.slice(4, 8);
            } else {
                return sortList.slice(0, 4);
            }

        } catch (e) {
            return match.match_basic_dict.arena_team.slice(0, 4)
        }
    }, [match]);
    return (
        <ArenaWrapper>
            {list.map((teamInfo) => {
                return (
                    <SummonerBox key={teamInfo.id}>
                        {teamInfo.participant_puu_ids.map((pId) => {
                            const player = match.participants_list.find((data) => data.puu_id === pId);
                            const name = getName(player);
                            if (!player) return null;
                            return (
                                <FlexBox height={'auto'} margin={'3px 0 0 0'} alignItems={'center'} key={pId}>
                                    <ImageBox
                                        borderRadius={hide ? '3px' : player.puu_id === myMatch.puu_id ? '50%' : '3px'}
                                        onClick={(e) => {
                                            e.stopPropagation()
                                        }}
                                    >
                                        <PlayerMarginBox>
                                            <ChampionImageWithLane
                                                disabled={true}
                                                champion_id={player.champion_id}
                                            />
                                        </PlayerMarginBox>
                                    </ImageBox>
                                    {player.pro_streamer_status &&
                                        <PlayerMarginBox>
                                            <SmallInfluInfo status={player.pro_streamer_status}/>
                                        </PlayerMarginBox>}
                                    <p className={getPClassName(player.puu_id, player?.withTogether)}>
                                        <ToSummonerLink name={`${player.riot_id_name}-${player.riot_id_tag_line}`}
                                                        region={region} puu_id={player.puu_id}>

                                                    <span
                                                        className="common_search"
                                                    >
                                                       {name}
                                                    </span>
                                        </ToSummonerLink>
                                    </p>
                                    {(player?.withTogether && match?.togetherCount > 1) &&
                                        <BigTooltip tooltipText={t('summoner.withTogether')}>
                                            <img src={'/images/ico_team.svg'} width={12} height={12} alt={""}/>
                                        </BigTooltip>
                                    }
                                </FlexBox>
                            )
                        })}
                    </SummonerBox>
                )
            })}
        </ArenaWrapper>
    );
};

export default SmallSummonerArenaList;