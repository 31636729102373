import React, {useCallback, useEffect, useMemo} from 'react';
import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux";
import AutoCompleteContent from "./AutoCompleteContent";
import {useHistory} from "react-router-dom";
import {GET_AUTO_COMPLETE_REQUEST} from "../../../../store/reducer/summoner";
import {useTranslation} from "react-i18next";
import useChampionFunc from "../../../../common/hooks/useChampionFunc";
import {FlexBox} from "../../../ui/styled";
import {getRegionServer, shortChampionIdsAndText} from "../../../../function";
import {Buffer} from 'buffer/'

const Wrapper = styled.div`
  display: ${props => {
    let visible = 'none';
    if (props?.summonerName) visible = 'block';
    return visible;
  }};
  width: 100%;
`;


const SumWrapper = styled.div`
  width: 100%;
  border: 1px solid ${props => props.theme.color.purple_100};
  border-radius: 6px;
  overflow: hidden;
  margin-bottom: 6px;
  box-shadow: 0 0px 6px rgba(0, 76, 255, 0.3);
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: ${props => props.theme.color.site_main_color};
  padding: 12px;
`;

const ContentText = styled.span`
  font-size: 14px;
  margin-left: 4px;
  color: ${props => props.theme.color.dark_100};
`;
const HeaderText = styled.span`
  color: ${props => props.theme.color.dark_200};
  font-weight: bold;
  font-size: 12px;
`;
const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 9px;
  background-color: ${props => props.theme.color.site_main_color};
  border-bottom: 1px solid ${props => props.theme.color.default_border_color};
`;

const RotateImg = styled.img`
  animation: rotate_image 1.2s linear infinite;
  @keyframes rotate_image {
    100% {
      transform: rotate(360deg);
    }
`;

const EmptyWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 2px;
`;

const ChampionWrapper = styled.div`
  max-height: 200px;
  overflow-y: auto;

  ::-webkit-scrollbar {

    width: 6px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background-color: ${props => props.theme.color.purple_200};
  }

  ::-webkit-scrollbar-track {
    margin: 10px;
    background-color: ${props => props.theme.color.purple_300};
    border-radius: 6px;
  }
`;

const ProText = styled.span`
  margin-left: 10px;
  font-size: 12px;
`;

function AutoCompleteHeader({title}) {
    return <HeaderWrapper>
        <HeaderText>
            {title}
        </HeaderText>
    </HeaderWrapper>;
}


const AutoComplete = ({summonerName, region, focus}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const version = useSelector((state) => state?.gameVersion.version);
    const {autoComplete, influData, getAutoCompleteLoading} = useSelector((state) => state.header);
    const history = useHistory();
    const {championData, getChampionName} = useChampionFunc();
    const championFiltered = useMemo(() => {
        if (championData.length > 0 && summonerName) {
            try {
                const regex = new RegExp(`^${summonerName.toLowerCase().replace(/\s+/g, '')}+`);
                return championData.filter((data) => {
                    const getShortName = shortChampionIdsAndText(data.champion_id);

                    if (getShortName.includes(summonerName)) {
                        return true;
                    }
                    const enName = data.champion_name_en.toLowerCase();
                    const filterEnName = enName.replace(/'|"/, "");
                    return regex.test(filterEnName) || regex.test(data.cho_name) || regex.test(data.champion_name.replace(/\s+/g, ''))
                });
            } catch (e) {
                console.log("invalid regex");
            }

        }
        return [];
    }, [summonerName]);

    const onClickChampion = useCallback((data) => {
        const lowerName = data.champion_name_en.replace(/\s|&|willump|!|/gi, "").toLowerCase();
        history.push(`/champions/${lowerName}/build`);
    }, []);

    const onClickPro = useCallback((player_name,) => {
        history.push(`/pro/${player_name}`)
    }, []);
    const onClickStreamer = useCallback((player_name,) => {
        history.push(`/strm/${player_name}`)
    }, []);

    const onClickSummoner = useCallback((data) => {
        if (data.summoner_id) {
            history.push(`/summoner/${region.toLowerCase()}/${data.riot_id_name}-${data.riot_id_tag_line}`)
        }
    }, [region]);

    useEffect(() => {
        if (Buffer.byteLength(summonerName.trim()) > 1) {
            dispatch({
                type: GET_AUTO_COMPLETE_REQUEST,
                data: {
                    username: summonerName,
                    region: region.toUpperCase(),
                }
            })
        }
    }, [summonerName, region])
    //loading
    if (!focus) return null;

    if (getAutoCompleteLoading) {
        return (
            <Wrapper summonerName={summonerName}>
                {championFiltered.length > 0 &&
                    <SumWrapper>
                        <HeaderWrapper>
                            <HeaderText>
                                {t('header.navChampions')}
                            </HeaderText>
                        </HeaderWrapper>
                        <ContentWrapper>
                            <ChampionWrapper>
                                {championFiltered.map((data) => {
                                    return (
                                        <AutoCompleteContent
                                            onClick={onClickChampion}
                                            data={data}
                                            key={data.champion_id}
                                            img={`${process.env.REACT_APP_IMG}${version}/img/champion/${data.image_name}__64.webp`}
                                            name={getChampionName(data)}
                                        />
                                    )
                                })}
                            </ChampionWrapper>
                        </ContentWrapper>
                    </SumWrapper>}
                <SumWrapper>
                    <ContentWrapper>
                        <EmptyWrapper>
                            <RotateImg
                                width={20}
                                height={20}
                                src={'/images/ico_input_search_loading.svg'}
                            />
                            <ContentText>{t("autoComplete.search")}</ContentText>
                        </EmptyWrapper>
                    </ContentWrapper>
                </SumWrapper>

            </Wrapper>
        )
    }

    return (
        <Wrapper summonerName={summonerName}>
            {championFiltered.length > 0 &&
                <SumWrapper>
                    <AutoCompleteHeader title={t('header.navChampions')}/>
                    <ContentWrapper>
                        <ChampionWrapper>
                            {championFiltered.map((data) => {
                                return (
                                    <AutoCompleteContent
                                        onClick={onClickChampion}
                                        data={data}
                                        key={data.champion_id}
                                        img={`${process.env.REACT_APP_IMG}${version}/img/champion/${data.image_name}__64.webp`}
                                        name={getChampionName(data)}
                                    />
                                )
                            })}
                        </ChampionWrapper>
                    </ContentWrapper>
                </SumWrapper>}

            {influData?.pro?.length > 0 &&
                <SumWrapper>
                    <AutoCompleteHeader
                        title={<FlexBox alignItems={'center'}>
                            <img src={'/images/tag_pro.svg'} width={34}
                                 alt={""}
                                 height={14}/>
                            <ProText>{t("autoComplete.pro")}</ProText>
                        </FlexBox>}/>

                    <ContentWrapper>
                        <ChampionWrapper>
                            {influData.pro.map((data, index) => {
                                const platform_id = getRegionServer(data.platform_id);
                                return (
                                    <AutoCompleteContent
                                        onClick={() => onClickPro(data.url_name)}
                                        data={data}
                                        key={index}
                                        img={`${process.env.REACT_APP_ROOT_IMG}pro/${data.championship_name}.png`}
                                        name={data.player_name}
                                        teamLogo={data.team_name}
                                        errorImg={'/images/img_unknown_32px.svg'}
                                    />
                                )
                            })}
                        </ChampionWrapper>
                    </ContentWrapper>
                </SumWrapper>}

            {influData?.streamer?.length > 0 &&
                <SumWrapper>
                    <AutoCompleteHeader
                        title={<FlexBox alignItems={'center'}>
                            <img src={'/images/tag_strm.svg'} width={34}
                                 alt={''}
                                 height={14}/>
                            <ProText>{t("autoComplete.streamer")}</ProText>
                        </FlexBox>}/>
                    <ContentWrapper>
                        <ChampionWrapper>
                            {influData.streamer.map((data, index) => {
                                const platform_id = getRegionServer(data.platform_id);
                                return (
                                    <AutoCompleteContent
                                        onClick={() => onClickStreamer(data.player_name)}
                                        data={data}
                                        key={index}
                                        img={`${process.env.REACT_APP_ROOT_IMG}streamer/${data.player_name}.png`}
                                        name={data.player_name}
                                        errorImg={'/images/img_unknown_32px.svg'}
                                    />
                                )
                            })}
                        </ChampionWrapper>
                    </ContentWrapper>
                </SumWrapper>}

            <SumWrapper>
                <AutoCompleteHeader title={t('header.navSummoner')}/>
                <ContentWrapper>
                    <ChampionWrapper>
                        {autoComplete.length > 0 ?
                            autoComplete.map((summoner, index) => {
                                return (
                                    <AutoCompleteContent
                                        tier={summoner?.tier}
                                        division={summoner?.division}
                                        onClick={onClickSummoner}
                                        data={summoner}
                                        key={summoner?.summoner_id}
                                        img={`${process.env.REACT_APP_IMG}img/profileicon/${summoner?.profile_id}__56.webp`}
                                        name={summoner?.riot_id_name}
                                        tag={summoner?.riot_id_tag_line}
                                    />
                                )
                            })
                            :
                            <EmptyWrapper>
                                <img
                                    width={20}
                                    height={20}
                                    src={'/images/ico_input_search_empty_box.svg'}
                                    alt={""}
                                />
                                <ContentText>{t("autoComplete.noData")}</ContentText>
                            </EmptyWrapper>
                        }
                    </ChampionWrapper>
                </ContentWrapper>
            </SumWrapper>
        </Wrapper>
    );
};

export default AutoComplete;
