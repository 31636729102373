import {useEffect, useState} from 'react';
import regionList from "../store/static_json/regionList";
import {LANGUAGE_FOR_OS} from "../i18n/types";
import {Buffer} from "buffer/";
import {
    AI_SCORE_BAD,
    AI_SCORE_BAD_TEXT,
    AI_SCORE_GOOD,
    AI_SCORE_GOOD_TEXT,
    AI_SCORE_PERFECT,
    AI_SCORE_PERFECT_TEXT,
    AI_SCORE_VERY_BAD,
    AI_SCORE_VERY_BAD_TEXT
} from "../styled/scoreColor";
import _ from "lodash";


const oldVersion = ['13.13', '13.12'];


export const allOffObject = (copyData) => {
    if (typeof copyData === 'object') {
        const copy = {...copyData};
        Object.keys(copy).forEach((key) => {
            copy[key].sortType = true;
        })
        return copy;
    }
    return copyData;
}

export const prevTierAndDivision = (tier, division) => {
    if (typeof tier === 'string') {
        if (tier.toLowerCase() === 'grandmaster' || tier.toLowerCase() === 'master' || tier.toLowerCase() === 'challenger') {
            return tier.toLowerCase();
        } else {
            return `${tier.toLowerCase()} ${division}`;
        }
    }

};

export const addTag = (region) => {

    if (typeof region === 'string') {
        const currentRegion = regionList.find((data) => data.title.toUpperCase() === region.toUpperCase());
        if (currentRegion) {
            return currentRegion.tagName;
        }
    }
    return 'KR1';
}
export const getRegion = (region) => {
    if (typeof region === 'string') {
        const currentRegion = regionList.find((data) => data.title.toUpperCase() === region.toUpperCase());
        if (currentRegion) {
            return currentRegion.serverName;
        }
    }
    return 'KR';
}

export const getRegionDefaultAll = (region) => {
    if (typeof region === 'string') {
        const currentRegion = regionList.find((data) => data.title.toUpperCase() === region.toUpperCase());
        if (currentRegion) {
            return currentRegion.serverName;
        }
    }
    return 'All';
}


export const getRegionServer = (region) => {
    if (typeof region === 'string') {
        const currentRegion = regionList.find((data) => data.serverName === region);
        if (currentRegion) {
            return currentRegion.title;
        }
    }
    return 'KR';
}

const _testHasDot = (text) => {
    if (typeof text === 'string') {
        const regexp = /\.+/;
        const testResult = regexp.test(text);
        return testResult ? text.split(".") : text;
    }
    return "";
}

export const storePuuIdLocal = (name, region, data, extra) => {
    if (data) {
        localStorage.removeItem("tempPuuId")
        localStorage.setItem("tempPuuId", JSON.stringify(data));
    }

    const url = `/summoner/${region}/${name}${extra ? '/' + extra : ''}`;
    window.open(url, "_blank", "noreferrer");
}
export const onStorePuuId = (name, region, data, extra) => {
    if (data) {
        // localStorage.setItem("tempPuuId", JSON.stringify(data));
    }
    const url = `/summoner/${region}/${name}${extra ? '/' + extra : ''}`;
    return url
    // window.open(url, "_blank", "noreferrer");
};

export const openToSummonerStory = ({region,name}) => {
    const url = `/summoner/${region}/${name}/story`;
    return url
}

export const openToSummonerMatch = ({region,name,matchId}) => {
    const url = `/summoner/${region}/${name}/matches/${matchId}`;
    return url
}
export const openToSummoner = ({region,name}) => {
    const url = `/summoner/${region}/${name}`;
    return url
}

export const getNestedObject = (text = '', obj) => {
    try {

        if (typeof text === 'string' && typeof obj === 'object' && Object.values(obj).length > 0) {
            const testedValue = _testHasDot(text);
            if (Array.isArray(testedValue)) {
                const result = testedValue.reduce((sum, cur) => sum[cur], obj);
                return result;
            } else {
                return obj[text];
            }
        }
        return [];

    } catch (e) {
        return [];
    }

};

export const cho_convertor = (value) => {
    value = value.replace("ㄳ", "ㄱㅅ");
    value = value.replace("ㄵ", "ㄴㅈ");
    value = value.replace("ㄶ", "ㄴㅎ");
    value = value.replace("ㄺ", "ㄹㄱ");
    value = value.replace("ㄻ", "ㄹㅁ");
    value = value.replace("ㄼ", "ㄹㅂ");
    value = value.replace("ㄽ", "ㄹㅅ");
    value = value.replace("ㄾ", "ㄹㅌ");
    value = value.replace("ㄿ", "ㄹㅍ");
    value = value.replace("ㅀ", "ㄹㅎ");
    value = value.replace("ㅄ", "ㅂㅅ");
    return value;
};

export const useTitle = (initTitle) => {
    const [title, setTitle] = useState(initTitle);

    const updateTitle = () => {
        const htmlTitle = document.querySelector('title');
        htmlTitle.innerText = title;
        if (title !== 'Loading...') {
            const setMeta = document.querySelector('meta[name=title]');
            const ogMeta = document.querySelector('meta[property="og:title"]')
            const twiter = document.querySelector('meta[property="twitter:title"]')
            setMeta.setAttribute('content', title);
            ogMeta.setAttribute('content', title);
            twiter.setAttribute('content', title);
        }
    };

    useEffect(updateTitle, [title]);

    return setTitle;
};


export const useDescription = (initTitle) => {
    const [title, setTitle] = useState(initTitle);

    const updateTitle = () => {
        if (title !== 'Loading...') {
            const setMeta = document.querySelector('meta[name=description]');
            const ogMeta = document.querySelector('meta[property="og:description"]')
            const twiter = document.querySelector('meta[property="twitter:description"]')
            setMeta.setAttribute('content', title);
            ogMeta.setAttribute('content', title);
            twiter.setAttribute('content', title);
        }
    };

    useEffect(updateTitle, [title]);

    return setTitle;
};


export const setSummonerName = (userName) => {
    let summonerName = userName.trim();
    encodeURIComponent(summonerName);
    if (Buffer.byteLength(summonerName) === 2 && userName.length === 2) {
        return summonerName[0] + " " + summonerName[1];
    }
    if (summonerName.length === 1) {
        return encodeURIComponent(userName);
    }
    return summonerName
}

export const getShortTime = (unixTime, lang) => {
    if (unixTime) {
        const gameDate = new Date(unixTime * 1000);
        const nowDate = new Date();
        const diffDate = new Date(nowDate - gameDate);
        const minutes = Math.floor(diffDate / 60000);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);

        let result = 0;


        if (minutes > 60) {
            if (hours > 24) {
                if (days > 365) {
                    result = lang === 'KOR' ? '오래 전' : 'long time ago';
                } else {
                    if (Math.abs(days) === 1) {
                        result = Math.abs(days) + (lang === 'KOR' ? '일 전' : 'd ago');
                    } else {
                        result = Math.abs(days) + (lang === 'KOR' ? '일 전' : 'd ago');
                    }

                }
            } else {
                if (Math.abs(hours) === 1) {
                    result = Math.abs(hours) + (lang === 'KOR' ? '시간 전' : 'h ago');
                } else {
                    result = Math.abs(hours) + (lang === 'KOR' ? '시간 전' : 'h ago');
                }
            }
        } else {
            if (Math.abs(minutes) === 1) {
                result = Math.abs(minutes) + (lang === 'KOR' ? '분 전' : 'm ago');
            } else {
                result = Math.abs(minutes) + (lang === 'KOR' ? '분 전' : 'm ago');
            }

        }

        return result;
    }

};


export const getDividedGameTime = (unixTime, lang) => {
    if (unixTime) {
        const gameDate = new Date(unixTime * 1000);
        const nowDate = new Date();
        const diffDate = new Date(nowDate - gameDate);
        const minutes = Math.floor(diffDate / 60000);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);

        let result = 0;
        let text = '';


        if (minutes > 60) {
            if (hours > 24) {
                if (days > 365) {
                    text = lang === 'KOR' ? '오래 전' : 'long time ago';
                } else {
                    if (Math.abs(days) === 1) {
                        result = Math.abs(days);
                        text = (lang === 'KOR' ? '일 전' : ' day ago');
                    } else {
                        result = Math.abs(days)
                        text = (lang === 'KOR' ? '일 전' : ' days ago');
                    }

                }
            } else {
                result = Math.abs(hours);
                text = (lang === 'KOR' ? '방금 전' : ' hour ago');
            }
        } else {
            result = Math.abs(minutes);
            text = (lang === 'KOR' ? '방금 전' : ' hour ago');
        }

        return [result, text];
    }

};


export const getLPTime = (unixTime, lang) => {
    if (unixTime) {
        const gameDate = new Date(unixTime * 1000);
        const nowDate = new Date();
        nowDate.setHours(24, 0, 0, 0);
        const diffDate = nowDate - gameDate;
        const minutes = Math.trunc(diffDate / 60000);
        const hours = Math.trunc(minutes / 60);
        const days = Math.trunc(hours / 24);

        let result = 0;


        if (hours > 24) {
            if (days > 365) {
                result = lang === 'KOR' ? '오래 전' : 'long time ago';
            } else {
                if (Math.abs(days) === 1) {
                    result = Math.abs(days) + (lang === 'KOR' ? '일 전' : ' d+');
                } else {
                    result = Math.abs(days) + (lang === 'KOR' ? '일 전' : ' d+');
                }

            }
        } else {
            result = lang === 'KOR' ? '오늘' : "today"
        }


        return result;
    }
};

// 게임시간 가져오기
export const getGameTime = (unixTime, lang) => {
    if (unixTime) {
        const gameDate = new Date(unixTime * 1000);
        const nowDate = new Date();
        const diffDate = nowDate - gameDate;
        const minutes = Math.trunc(diffDate / 60000);
        const hours = Math.trunc(minutes / 60);
        const days = Math.trunc(hours / 24);

        let result = 0;


        if (minutes > 60) {
            if (hours > 24) {
                if (days > 365) {
                    result = lang === 'KOR' ? '오래 전' : 'long time ago';
                } else {
                    if (Math.abs(days) === 1) {
                        result = Math.abs(days) + (lang === 'KOR' ? '일 전' : ' day ago');
                    } else {
                        result = Math.abs(days) + (lang === 'KOR' ? '일 전' : ' days ago');
                    }

                }
            } else {
                if (Math.abs(hours) === 1) {
                    result = Math.abs(hours) + (lang === 'KOR' ? '시간 전' : ' hour ago');
                } else {
                    result = Math.abs(hours) + (lang === 'KOR' ? '시간 전' : ' hours ago');
                }
            }
        } else {
            if (Math.abs(minutes) === 1) {
                result = Math.abs(minutes) + (lang === 'KOR' ? '분 전' : ' min ago');
            } else {
                result = Math.abs(minutes) + (lang === 'KOR' ? '분 전' : ' minutes ago');
            }

        }

        return result;
    }

};

export const patchHistoryTime = (unixTime, lang) => {
    if (unixTime) {
        const gameDate = new Date(unixTime * 1000);
        const nowDate = new Date();
        const diffDate = nowDate - gameDate;
        const minutes = Math.trunc(diffDate / 60000);
        const hours = Math.trunc(minutes / 60);
        const days = Math.trunc(hours / 24);

        let result = 0;


        if (minutes > 60) {
            if (hours > 24) {
                if (Math.abs(days) === 1) {
                    result = Math.abs(days) + (lang === 'KOR' ? '일 전' : ' day ago');
                } else {
                    result = Math.abs(days) + (lang === 'KOR' ? '일 전' : ' days ago');
                }

            } else {
                if (Math.abs(hours) === 1) {
                    result = Math.abs(hours) + (lang === 'KOR' ? '시간 전' : ' hour ago');
                } else {
                    result = Math.abs(hours) + (lang === 'KOR' ? '시간 전' : ' hours ago');
                }
            }
        } else {
            if (Math.abs(minutes) === 1) {
                result = Math.abs(minutes) + (lang === 'KOR' ? '분 전' : ' min ago');
            } else {
                result = Math.abs(minutes) + (lang === 'KOR' ? '분 전' : ' minutes ago');
            }

        }

        return result;
    }

};


// 게임시간 시/분/초 를 초단위로


// 게임시간 시/분/초 구하기
export const getTimeHMS = (time, num, lang) => {
    let tmp = time.split(':');
    let hour = parseInt(tmp[0]);
    let minute = parseInt(tmp[1]) > 9 ? parseInt(tmp[1]) : `0${parseInt(tmp[1])}`;
    let second = parseInt(tmp[2]) > 9 ? parseInt(tmp[2]) : `0${parseInt(tmp[2])}`;

    if (num === 'num') {
        return hour !== 0
            ? `${hour}:${minute}:${second}`
            : `${minute}:${second}`;
    } else {

        if (lang === 'KOR') {
            return hour !== 0 ? `${hour}시간 ${minute}분 ${second}초` : `${minute}분 ${second}초`;
        } else {
            return hour !== 0 ? `${hour}:${minute}:${second}` : `${minute}:${second}`;
        }
    }
};

// 시간 포멧
export const getFormatTime = (time) => {
    // time은 xx.xx 형식
    let returnVal = '';
    let min = Math.floor(time);
    let sec = time - Math.floor(time);
    if (sec === 0) {
        sec = '00';
    } else {
        if (Math.round(sec * 60) === 60) {
            min += 1;
            sec = '00';
        } else {
            sec = (Math.round(sec * 60) < 10 ? '0' : '') + Math.round(sec * 60);
        }
    }
    returnVal = min + ':' + sec;
    return returnVal;
};

// 인게임 시간 구하기
export const getIngameTime = (duration) => {
    let hour = parseInt(duration / 3600);
    let min = parseInt((duration % 3600) / 60);
    let sec = duration % 60;

    if (hour) {

        return `${hour}:${min < 10 ? '0' + min : min}:${sec < 10 ? '0' + sec : sec}`;
    }

    return `${min}:${sec < 10 ? '0' + sec : sec}`;
};

// 팀별 우세도 계산
export const getWinrateCalc = (winrate, team) => {
    let result = 0;
    if (winrate > 0) {
        // 블루가 우세
        team === 'blue' ? (result = winrate + 50) : (result = winrate - 50);
    } else {
        // 레드가 우세
        team === 'blue' ? (result = 100 + (winrate - 50)) : (result = winrate - 50);
    }
    result = Math.abs(result);
    return result;
};

// 음수 양수 판단
export const getNeGa = (arg) => {
    let result = '';
    if (arg === 0) {
        result = '';
    } else if (arg > 0) {
        result = 'positive';
    } else {
        result = 'negative';
    }
    return result;
};

// LP Score 계산
export const getLPScore = (tier, division, lp, grandvalue, challengervalue, multiPly = 100) => {
    let returnValue = lp;
    let divisionCount = 4;

    if (tier === 'IRON') {
        divisionCount *= 1;
        returnValue += multiPly * (divisionCount - division);
    } else if (tier === 'BRONZE') {
        divisionCount *= 2;
        returnValue += multiPly * (divisionCount - division);
    } else if (tier === 'SILVER') {
        divisionCount *= 3;
        returnValue += multiPly * (divisionCount - division);
    } else if (tier === 'GOLD') {
        divisionCount *= 4;
        returnValue += multiPly * (divisionCount - division);
    } else if (tier === 'PLATINUM') {
        divisionCount *= 5;
        returnValue += multiPly * (divisionCount - division);
    } else if (tier === 'EMERALD') {
        divisionCount *= 6;
        returnValue += multiPly * (divisionCount - division);
    } else if (tier === 'DIAMOND') {
        divisionCount *= 7;
        returnValue += multiPly * (divisionCount - division);
    } else if (tier === 'MASTER') {
        returnValue += 2800;
    } else if (tier === 'GRANDMASTER') {
        returnValue += grandvalue * (multiPly / 100);
    } else if (tier === 'CHALLENGER') {
        returnValue += challengervalue * (multiPly / 100);
    }

    return returnValue;
};

// 승률 구하기


// KDA 구하기
export const getKda = (k, d, a) => {
    let result = 0;
    if (d === 0) {
        result = Math.round((k + a) * 1.2 * 100) / 100;
    } else {
        result = Math.round(((k + a) / d) * 100) / 100;
    }
    return result;
};

// AI Score 구하기
export const getAIScore = (minmax_z) => {
    let scoreResult = 0;
    scoreResult = (minmax_z * 100).toFixed(0);

    return scoreResult;
};


const setMostData = (array, flag) => {
    const copyArray = [...array];
    const result = [];

    for (let i in copyArray) {
        const findIndex = result.findIndex((data, index) => data[flag] === copyArray[i][flag]);
        if (findIndex > -1) {
            result[findIndex] = {
                ...result[findIndex],
                gameCount: result[findIndex].gameCount + 1,
                ai_score: result[findIndex].ai_score + copyArray[i].ai_score,
                wins: copyArray[i].win ? result[findIndex].wins + 1 : 0,
            }
        } else {
            result.push({
                gameCount: 1,
                [flag]: copyArray[i][flag],
                ai_score: copyArray[i].ai_score,
                wins: copyArray[i].win ? 1 : 0,
            });
        }

    }

    result.sort((a, b) => {
        if (a.gameCount < b.gameCount) {
            return 1;
        } else if (a.gameCount > b.gameCount) {
            return -1;
        } else if (a.gameCount === b.gameCount) {
            const aScore = a.ai_score / a.gameCount;
            const bScore = b.ai_score / b.gameCount;
            if (aScore < bScore) {
                return 1;
            } else if (aScore > bScore) {
                return -1;
            } else if (aScore === bScore) {
                if (a.wins < b.wins) {
                    return 1;
                } else if (a.wins < b.wins) {
                    return -1;
                } else if (a.wins === b.wins) {
                    return 0;
                }
            }
        }
    });


    return result;
}

export const championCapitalize = (value) => {

    if (typeof value === 'string') {
        const replaced = value.replace("'", "");
        if (replaced === "reksai") {
            return 'RekSai';
        }
        if (replaced === 'leesin' || replaced === "Lee Sin") {
            return 'LeeSin';
        }
        if (replaced === 'masteryi') {
            return 'MasterYi';
        }
        if (replaced === 'drmundo') {
            return 'DrMundo';
        }
        if (replaced === 'missfortune') {
            return 'MissFortune'
        }
        if (replaced === 'xinzhao') {
            return "XinZhao"
        }
        if (replaced === 'aurelionsol') {
            return "AurelionSol"
        }
        if (replaced === 'monkeyking') {
            return "MonkeyKing"
        }
        if (replaced === 'jarvaniv') {
            return "JarvanIV"
        }
        if (replaced === 'kogmaw') {
            return "KogMaw"
        }
        if (replaced === 'ksante') {
            return "KSante"
        }
        if (replaced === 'tahmkench') {
            return "TahmKench";
        }
        if (replaced === 'twistedfate') {
            return "TwistedFate"
        }
        if (replaced === 'wukong') {
            return "MonkeyKing"
        }
        return replaced.charAt(0).toUpperCase() + replaced.slice(1);
    }
    return "";
}

export const capitalizeLetter = (value) => {
    if (typeof value === 'string') {
        return value.charAt(0).toUpperCase() + value.slice(1);
    }
}


export const threedigits = (value) => {
    if (value !== undefined && (typeof value === 'string' || typeof value === 'number')) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }
}

export const urlChecker = (url) => {
    const copyUrl = url;
    const lastword = copyUrl.charAt(copyUrl.length - 1);
    let finalUrl = url;
    if (lastword === '/') {
        finalUrl = finalUrl.substr(0, finalUrl.length - 1);
    }
    return finalUrl;
}
export const sortNestedObject = (data, filter, extra = () => {
}) => {
    try {
        const copyData = [...data];
        const ascending = [-1, 1, 0];
        const decending = [1, -1, 0];


        copyData.sort((a, b) => {
            const firstFilter = extra && extra(a, b);
            if (!firstFilter) {
                for (const condition in filter) {
                    const compare = filter[condition];
                    const sortMath = compare.sortType ? [...ascending] : [...decending];
                    const firstValue = compare.value?.split?.(".")?.reduce((sum, cur) => sum[cur], a);
                    const secondValue = compare.value?.split?.(".")?.reduce((sum, cur) => sum[cur], b);
                    if (firstValue < secondValue) {
                        return sortMath[0];
                    } else if (firstValue > secondValue) {
                        return sortMath[1];
                    }
                }
                return 0;
            } else {
                return firstFilter
            }
        })


        return copyData.map((data, index) => {
            return {
                ...data,
                cid: index,
            }
        });
    } catch (e) {
        console.log("sortingError", e);
        return data;
    }
}


export const getLineRank = (array) => {
    const sortedData = setMostData(array, 'lane');
    const result = [];
    result.push(sortedData[0].lane);
    if (sortedData.length > 1) {
        result.push(sortedData[1].lane);
    }
    return result;
}


export const getHighRankChampion = (array) => {
    const sortedData = setMostData(array, 'champion_id');
    const result = [];
    result.push(sortedData[0].champion_id);
    if (sortedData.length > 1) {
        result.push(sortedData[1].champion_id);
    }


    return result;

}

// 배열의 중복데이터 중 최다 value와 중복 횟수 구하기 (내림차순으로 return)


export const getAiPrediction = (tier, aiscore, data) => {

    if (tier && aiscore !== undefined) {
        let userTier = tier.tier + tier.division;
        const tiers = {
            IRONIV: {
                value: 47,
                ranks: 'I4',
            },
            IRONIII: {
                value: 47,
                ranks: 'I3',
            },
            IRONII: {
                value: 47,
                ranks: 'I2',
            },
            IRONI: {
                value: 47,
                ranks: 'I1',
            },
            BRONZEIV: {
                value: 47,
                ranks: 'B4',
            },
            BRONZEIII: {
                value: 47,
                ranks: 'B3'
            },
            BRONZEII: {
                value: 47,
                ranks: 'B2',
            },
            BRONZEI: {
                value: 47,
                ranks: 'B1'
            },
            SILVERIV: {
                value: 48,
                ranks: 'S4',
            },
            SILVERIII: {
                value: 48,
                ranks: 'S3',
            },
            SILVERII: {
                value: 48,
                ranks: 'S2',
            },
            SILVERI: {
                value: 48,
                ranks: 'S1',
            },
            GOLDIV: {
                value: 48,
                ranks: 'G4'
            },
            GOLDIII: {
                value: 48,
                ranks: 'G3'
            },
            GOLDII: {
                value: 48,
                ranks: 'G2',
            },
            GOLDI: {
                value: 48,
                ranks: 'G1',
            },
            PLATINUMIV: {
                value: 48,
                ranks: 'P4',
            },
            PLATINUMIII: {
                value: 48,
                ranks: 'P3',
            },
            PLATINUMII: {
                value: 48,
                ranks: 'P2',
            },
            PLATINUMI: {
                value: 48,
                ranks: 'P1',
            },
            EMERALDI: {
                value: 49,
                ranks: 'E1',
            },
            EMERALDII: {
                value: 49,
                ranks: 'E2',
            },
            EMERALDIII: {
                value: 49,
                ranks: 'E3',
            },
            EMERALDIV: {
                value: 49,
                ranks: 'E4',
            },
            DIAMONDIV: {
                value: 50,
                ranks: 'D4'
            },
            DIAMONDIII: {
                value: 50,
                ranks: 'D3'
            },
            DIAMONDII: {
                value: 51,
                ranks: 'D2'
            },
            DIAMONDI: {
                value: 52,
                overValue: 0,
                ranks: 'D1',
            },
            MASTERI: {
                value: 53,
                overValue: data?.GRANDMASTER || 100,
                ranks: 'M'
            },
            GRANDMASTERI: {
                value: 53,
                overValue: data?.CHALLENGER || 1000,
                ranks: 'GM',
            },
            CHALLENGERI: {
                value: 53,
                overValue: 10000,
                ranks: 'C'
            }
        }

        const ranks = ['I4', 'I3', 'I2', 'I1', 'B4', 'B3', 'B2', 'B1', 'S4', 'S3', 'S2', 'S1', 'G4', 'G3', 'G2', 'G1', 'P4', 'P3', 'P2', 'P1', 'E4', 'E3', 'E2', 'E1', 'D4', 'D3', 'D2', 'D1', 'M', 'M', 'M', 'GM', 'GM', 'GM', 'C'];
        const aiArray = [-3, -2, -2, -2, -2, -1, -1, -1, -1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 2, 2, 2, 2, 3];

        if (!tiers[userTier]) {
            userTier = 'BRONZEI';
        }

        const calculatedValue = aiscore - tiers[userTier].value;


        let caledAiValue;

        const userTierValue = ranks.findIndex((data) => data === tiers[userTier].ranks);
        if (calculatedValue < -9) {
            caledAiValue = aiArray[0];
        } else if (calculatedValue > 12) {
            caledAiValue = aiArray[aiArray.length - 1];
        } else {
            caledAiValue = aiArray[(calculatedValue + 9)]
        }

        if (userTier === 'MASTERI' || userTier === 'GRANDMASTERI' || userTier === "CHALLENGERI") {
            if (data) {
                const lpcal = tier.leaguePoints + (caledAiValue * 100);
                const currentTier = tiers[userTier];
                const prevTier = Object.entries(tiers).find((data) => data[1].ranks === ranks[userTierValue - 1])[1];

                if (lpcal > currentTier.overValue) {
                    caledAiValue = 3;
                } else if (prevTier.overValue > lpcal) {
                    caledAiValue = -1;
                } else {
                    caledAiValue = 0;
                }

            }
        }


        // let resultNum = 0;
        let resultTxt;
        const predictionValue = userTierValue + caledAiValue;


        if (predictionValue > ranks.length - 1) {
            resultTxt = ranks[ranks.length - 1];
        } else if (predictionValue < 0) {
            resultTxt = ranks[0];
        } else {
            resultTxt = ranks[predictionValue];
        }


        return {txt: resultTxt};
    }
}

export const romanNumberConverter = (value) => {
    switch (value) {
        case 'IV':
            return 4;
        case 'III':
            return 3;
        case 'II':
            return 2;
        case 'I' :
            return 1;
        default:
            return value;
    }
}
export const getDivisionValue = (division) => {
    switch (Number(division)) {
        case 4:
            return 0;
        case 3:
            return 100;
        case 2:
            return 200;
        case 1:
            return 300;
        default:
            return 0;
    }
}
export const romanNumberReverseConverter = (value) => {
    switch (value) {
        case 'IV':
            return 0;
        case 'III':
            return 1;
        case 'II':
            return 2;
        case 'I' :
            return 3;
        default:
            return 0;
    }
}
export const getTierNameByValue = (tierValue) => {
    switch (tierValue) {
        case 3100:
            return "CHALLENGER"
        case 3000:
            return "GRANDMASTER"
        case 2900:
            return "MASTER"
        case 2500:
            return 'DIAMOND'
        case 2100:
            return 'EMERALD'
        case 1700:
            return 'PLATINUM'
        case 1300:
            return 'GOLD'
        case 900:
            return 'SILVER'
        case 500:
            return 'BRONZE'
        case 100:
            return 'IRON'
        default:
            return 'IRON'
    }
}
export const getTierValue = (tier) => {
    let resultNum;
    switch (tier.toUpperCase()) {
        case 'CHALLENGER':
            resultNum = 3100;
            break;
        case 'GRANDMASTER':
            resultNum = 3000;
            break;
        case 'MASTER':
            resultNum = 2900;
            break;
        case 'DIAMOND':
            resultNum = 2500;
            break;
        case 'EMERALD':
            resultNum = 2100;
            break;
        case 'PLATINUM':
            resultNum = 1700;
            break;
        case 'GOLD':
            resultNum = 1300;
            break;
        case 'SILVER':
            resultNum = 900;
            break;
        case 'BRONZE':
            resultNum = 500;
            break;
        case 'IRON':
            resultNum = 100;
            break;
        default:
            resultNum = 0;
            break;
    }
    return resultNum;
}

// 티어 구하기, 티어 예측 구하기
export const getTierScore = (tier, aiscore) => {
    let resultNum = 0;
    let resultTxt = '';
    let predictNum = 0;
    const obj = {
        tier: "",
        division: "",
    }


    if (48 > aiscore) {
        predictNum += Math.floor((aiscore - 48) / 5) * 100;
    } else if (52 < aiscore) {
        predictNum += Math.ceil((aiscore - 52) / 5) * 100;
    } else {
        predictNum = 0;
    }

    if (typeof tier === 'number') {
        resultNum = Math.trunc(Math.round(tier / 100) * 100);
    } else {
        let str = tier.split(' ');
        switch (str[0].toUpperCase()) {
            case 'CHALLENGER':
                resultNum = 3000;
                resultTxt = 'C';
                break;
            case 'GRANDMASTER':
                resultNum = 2900;
                resultTxt = 'GM';
                break;
            case 'MASTER':
                resultNum = 2800;
                resultTxt = 'M';
                break;
            case 'DIAMOND':
                resultNum = 2400;
                resultTxt = 'D';
                break;
            case 'EMERALD':
                resultNum = 2000;
                resultTxt = 'D';
                break;
            case 'PLATINUM':
                resultNum = 1600;
                resultTxt = 'P';
                break;
            case 'GOLD':
                resultNum = 1200;
                resultTxt = 'G';
                break;
            case 'SILVER':
                resultNum = 800;
                resultTxt = 'S';
                break;
            case 'BRONZE':
                resultNum = 400;
                resultTxt = 'B';
                break;
            case 'IRON':
                resultNum = 0;
                resultTxt = 'I';
                break;
            default:
                resultNum = 0;
                break;
        }


        obj.tier = resultTxt;


        const upperText = str[0].toUpperCase();

        if (upperText !== 'CHALLENGER' && upperText !== 'GRANDMASTER' && upperText !== 'MASTER') {
            switch (str[1]) {
                case "4":
                    resultNum += 0;
                    resultTxt += '4';
                    obj.division = 4;
                    break;
                case '3':
                    resultNum += 100;
                    resultTxt += '3';
                    obj.division = 4;
                    break;
                case '2':
                    resultNum += 200;
                    resultTxt += '2';
                    obj.division = 4;
                    break;
                case '1':
                    resultNum += 300;
                    resultTxt += '1';
                    obj.division = 4;
                    break;
                default:
                    break;
            }
        }
    }


    if (aiscore !== undefined || typeof tier === 'number') {
        resultNum += predictNum;
        if (0 === resultNum) {
            resultTxt = 'U';
            obj.tier = 'Unranked'
            obj.division = ""
        } else if (0 <= resultNum && resultNum < 100) {
            resultTxt = 'I4';
            obj.tier = 'Iron'
            obj.division = 4
        } else if (100 <= resultNum && resultNum < 200) {
            resultTxt = 'I3';
            obj.tier = 'Iron'
            obj.division = 3
        } else if (200 <= resultNum && resultNum < 300) {
            resultTxt = 'I2';
            obj.tier = 'Iron'
            obj.division = 2
        } else if (300 <= resultNum && resultNum < 400) {
            resultTxt = 'I1';
            obj.tier = 'Iron'
            obj.division = 1
        } else if (400 <= resultNum && resultNum < 500) {
            resultTxt = 'B4';
            obj.tier = 'bronze'
            obj.division = 4
        } else if (500 <= resultNum && resultNum < 600) {
            resultTxt = 'B3';
            obj.tier = 'bronze'
            obj.division = 3
        } else if (600 <= resultNum && resultNum < 700) {
            resultTxt = 'B2';
            obj.tier = 'bronze'
            obj.division = 2
        } else if (700 <= resultNum && resultNum < 800) {
            resultTxt = 'B1';
            obj.tier = 'bronze'
            obj.division = 1
        } else if (800 <= resultNum && resultNum < 900) {
            resultTxt = 'S4';
            obj.tier = 'silver'
            obj.division = 4
        } else if (900 <= resultNum && resultNum < 1000) {
            resultTxt = 'S3';
            obj.tier = 'silver'
            obj.division = 3
        } else if (1000 <= resultNum && resultNum < 1100) {
            resultTxt = 'S2';
            obj.tier = 'silver'
            obj.division = 2
        } else if (1100 <= resultNum && resultNum < 1200) {
            resultTxt = 'S1';
            obj.tier = 'silver'
            obj.division = 1
        } else if (1200 <= resultNum && resultNum < 1300) {
            resultTxt = 'G4';
            obj.tier = 'gold'
            obj.division = 4
        } else if (1300 <= resultNum && resultNum < 1400) {
            resultTxt = 'G3';
            obj.tier = 'gold'
            obj.division = 3
        } else if (1400 <= resultNum && resultNum < 1500) {
            resultTxt = 'G2';
            obj.tier = 'gold'
            obj.division = 2
        } else if (1500 <= resultNum && resultNum < 1600) {
            resultTxt = 'G1';
            obj.tier = 'gold'
            obj.division = 1
        } else if (1600 <= resultNum && resultNum < 1700) {
            resultTxt = 'P4';
            obj.tier = 'platinum'
            obj.division = 4
        } else if (1700 <= resultNum && resultNum < 1800) {
            resultTxt = 'P3';
            obj.tier = 'platinum'
            obj.division = 3
        } else if (1800 <= resultNum && resultNum < 1900) {
            resultTxt = 'P2';
            obj.tier = 'platinum'
            obj.division = 2
        } else if (1900 <= resultNum && resultNum < 2000) {
            resultTxt = 'P1';
            obj.tier = 'platinum'
            obj.division = 1
        } else if (2000 <= resultNum && resultNum < 2100) {
            resultTxt = 'E4';
            obj.tier = 'emerald'
            obj.division = 4
        } else if (2100 <= resultNum && resultNum < 2200) {
            resultTxt = 'E3';
            obj.tier = 'emerald'
            obj.division = 3
        } else if (2200 <= resultNum && resultNum < 2300) {
            resultTxt = 'E2';
            obj.tier = 'emerald'
            obj.division = 2
        } else if (2300 <= resultNum && resultNum < 2400) {
            resultTxt = 'E1';
            obj.tier = 'emerald'
            obj.division = 1

        } else if (2400 <= resultNum && resultNum < 2500) {
            resultTxt = 'D4';
            obj.tier = 'diamond'
            obj.division = 4
        } else if (2500 <= resultNum && resultNum < 2600) {
            resultTxt = 'D3';
            obj.tier = 'diamond'
            obj.division = 3
        } else if (2600 <= resultNum && resultNum < 2700) {
            resultTxt = 'D2';
            obj.tier = 'diamond'
            obj.division = 2
        } else if (2700 <= resultNum && resultNum < 2800) {
            resultTxt = 'D1';
            obj.tier = 'diamond'
            obj.division = 1
        } else if (2800 <= resultNum && resultNum < 2900) {
            resultTxt = 'M';
            obj.tier = 'master'
        } else if (2900 <= resultNum && resultNum < 3000) {
            resultTxt = 'GM';
            obj.tier = 'grandmaster'
        } else {
            resultTxt = 'C';
            obj.tier = 'challenger'
        }
    }

    return {num: resultNum, txt: resultTxt, obj: {...obj}};
};


export const sortChampData = (data, filter, extra = () => {
},) => {
    try {
        const copyData = [...data];
        const ascending = [-1, 1, 0];
        const decending = [1, -1, 0];


        copyData.sort((a, b) => {
            const firstFilter = extra && extra(a, b);
            if (!firstFilter) {
                for (const condition in filter) {
                    const compare = filter[condition];
                    const sortMath = compare?.reverseSort ? compare.sortType ? [...decending] : [...ascending] : compare.sortType ? [...ascending] : [...decending];
                    if (a[compare.value] < b[compare.value]) {
                        return sortMath[0];
                    } else if (a[compare.value] > b[compare.value]) {
                        return sortMath[1];
                    }
                }
                return 0;
            } else {
                return firstFilter
            }

        })


        return copyData.map((data, index) => {
            return {
                ...data,
                cid: index,
            }
        });
    } catch (e) {
        console.log("sortingError", e);
        return data;
    }

}

// 객체 안의 객체 정렬
export const getSortedObject = (data, attr, flag) => {
    let arr = [];
    for (const prop in data) {
        if (data.hasOwnProperty(prop)) {
            let obj = {};
            obj[prop] = data[prop];
            obj.tempSortName = data[prop][attr];
            arr.push(obj);
        }
    }

    if (flag === 'ascending') {
        arr.sort(function (a, b) {
            let at = a.tempSortName,
                bt = b.tempSortName;
            return at > bt ? 1 : at < bt ? -1 : 0;
        });
    } else {
        arr.sort(function (a, b) {
            let at = a.tempSortName,
                bt = b.tempSortName;
            return at < bt ? 1 : at > bt ? -1 : 0;
        });
    }

    let result = [];
    for (let i = 0, l = arr.length; i < l; i++) {
        let obj = arr[i];
        delete obj.tempSortName;
        let id = 0;
        for (const prop in obj) {
            if (obj.hasOwnProperty(prop)) {
                id = prop;
            }
        }
        let item = obj[id];
        item.cid = Number(id);
        result.push(item);
    }

    // 배열이 리턴
    return result;
};

// 배열 객체를 정렬
export const getSortedArray = (array) => {
    let result = [];
    for (let i = 0; i < array.length; i++) {
        for (const key in array[i]) {
            result.push(array[i][key]);
        }
    }

    return result;
};

// 데미지를 퍼센트로 바꾸기


// 챔피언 중에 스킬 마스터 렙이 다른 챔피언이 있어서 스킬 마스터 count를 구해야함!
export const getMaxCount = (type, champion_id) => {
    /***
     * champion_id
     - 77 : 우디르 (6 6 6 6)
     - 126 : 제이스 (6 6 6 1)
     - 350 : 유미 (6 5 5 3)
     - 523 : 아펠리오스 (6 6 6 3)
     ***/
    let count = 5;
    if (type === 'Q') {
        if (
            champion_id === '77' ||
            champion_id === '126' ||
            champion_id === '350' ||
            champion_id === '523'
        ) {
            count = 6;
        }
    } else if (type === 'W') {
        if (champion_id === '77' || champion_id === '126' || champion_id === '523') {
            count = 6;
        }
    } else if (type === 'E') {
        if (champion_id === '77' || champion_id === '126' || champion_id === '523') {
            count = 6;
        }
    }

    return count;
};

// 점수 색상 바꾸기 (4 color)
export const scoreColor = (num = '-', extra = '') => {
    if (Number(num) === 100) {
        return <span className="score_color verygood">100{extra}</span>;
    } else if (num >= 75) {
        return <span className="score_color verygood">{num}{extra}</span>;
    } else if (num >= 50) {
        return <span className="score_color good">{num}{extra}</span>;
    } else if (num >= 25) {
        return <span className="score_color bad">{num}{extra}</span>;
    } else {
        return <span className="score_color verybad">{num}{extra}</span>;
    }
};

// kda 색상 바꾸기 (3 color)
export const kdaColor = (num, extra = '') => {
    if (num >= 4) {
        return <span className="kda_color high">{num}{extra}</span>;
    } else if (num >= 3) {
        return <span className="kda_color highMiddle">{num}{extra}</span>;
    } else if (num >= 2) {
        return <span className="kda_color middle">{num}{extra}</span>;
    } else {
        return <span className="kda_color low">{num}{extra}</span>;
    }
};

// 티어 뱃지 변경
export const tierBadge = (tier) => {
    if (tier) {

        if (tier === 'C') {
            return <span className="tier_badge challenger">{tier}</span>;
        } else if (tier === 'GM') {
            return <span className="tier_badge grandmaster"><span className={'grandmastertext'}>{tier}</span></span>;
        } else if (tier === 'M') {
            return <span className="tier_badge master">{tier}</span>;
        } else {
            switch (tier[0]) {
                case 'D':
                    return <span className="tier_badge diamond">{tier}</span>;
                case 'P':
                    return <span className="tier_badge platinum">{tier}</span>;
                case 'G':
                    return <span className="tier_badge gold">{tier}</span>;
                case 'S':
                    return <span className="tier_badge silver">{tier}</span>;
                case 'B':
                    return <span className="tier_badge bronze">{tier}</span>;
                case 'I':
                    return <span className="tier_badge iron">{tier}</span>;
                default:
                    return <span className="tier_badge unranked">U</span>;
            }
        }
    }
    return <span className="tier_badge unranked">U</span>
};

// 티어 텍스트 변경
export const tierText = (tier, division) => {
    let div = 0;
    if (division === 4) {
        div = 4;
    } else if (division === 3) {
        div = 3;
    } else if (division === 2) {
        div = 2;
    } else if (division === 1) {
        div = 1;
    }
    switch (tier) {
        case 'CHALLENGER':
            return <span className="tier_color challenger">Challenger</span>;
        case 'GRANDMASTER':
            return <span className="tier_color grandmaster">Grandmaster</span>;
        case 'MASTER':
            return <span className="tier_color master">Master</span>;
        case 'DIAMOND':
            return <span className="tier_color diamond">Diamond {`${div}`}</span>;
        case 'PLATINUM':
            return <span className="tier_color platinum">Platinum {`${div}`}</span>;
        case 'EMERALD':
            return <span className="tier_color emerald">Emerald {`${div}`}</span>;
        case 'GOLD':
            return <span className="tier_color gold">Gold {`${div}`}</span>;
        case 'SILVER':
            return <span className="tier_color silver">Silver {`${div}`}</span>;
        case 'BRONZE':
            return <span className="tier_color bronze">Bronze {`${div}`}</span>;
        case 'IRON':
            return <span className="tier_color iron">Iron {`${div}`}</span>;
        default:
            return <span className="tier_color unrank">Unranked</span>;
    }
};


export const onlyTierText = (tier,) => {

    if (typeof tier === 'string') {
        switch (tier.toUpperCase()) {
            case 'CHALLENGER':
                return <span className="tier_color challenger">Challenger</span>;
            case 'GRANDMASTER':
                return <span className="tier_color grandmaster">Grandmaster</span>;
            case 'MASTER':
                return <span className="tier_color master">Master</span>;
            case 'DIAMOND':
                return <span className="tier_color diamond">Diamond </span>;
            case 'PLATINUM':
                return <span className="tier_color platinum">Platinum</span>;
            case 'EMERALD':
                return <span className="tier_color emerald">Emerald </span>;
            case 'GOLD':
                return <span className="tier_color gold">Gold</span>;
            case 'SILVER':
                return <span className="tier_color silver">Silver</span>;
            case 'BRONZE':
                return <span className="tier_color bronze">Bronze </span>;
            case 'IRON':
                return <span className="tier_color iron">Iron</span>;
            default:
                return <span className="tier_color unrank">Unranked</span>;
        }
    }
    return <span className="tier_color unrank">Unranked</span>;
};

// 평균 티어 텍스트 변경2


// 분당 cs구하기
export const csPerMinute = (cs, time) => {
    let tmp = time.split(':');
    let minute = parseInt(tmp[1]);

    return (cs / minute).toFixed(1);
};

// 라인이름 줄이기
export const cutLaneName = (lane) => {
    let result = '';
    switch (lane) {
        case 'Top':
            result = 'Top';
            break;
        case 'Middle':
            result = 'Mid';
            break;
        case 'Jungle':
            result = 'Jug';
            break;
        case 'Bot':
            result = 'Bot';
            break;
        case 'Supporter':
            result = 'Sup';
            break;
        default:
            result = 0;
            break;
    }
    return result;
};

export const isAramMap = (data) => {
    switch (data) {
        case 1300:
            return true;
        case 450 :
            return true;
        case 720 :
            return true;
        case 900 :
            return true;
        default:
            return false;
    }
};
export const isAram = (data) => {
    switch (data) {
        case 1300:
            return true;
        case 1020:
            return true;
        case 450 :
            return true;
        case 720 :
            return true;
        case 900 :
            return true;
        default:
            return false;
    }
};

export const isArena = (data) => {
    switch (data) {
        case 1700:
            return true;
        case 1710:
            return true;
        default:
            return false;
    }
}

export const isSwarm = (data) => {
    switch (data) {
        case 1830:
            return true;
        case 1820:
            return true;
        case 1810:
            return true;
        case 1840:
            return true;
        case 3000:
            return true;
        default:
            return false;
    }
}
export const isRanked = (data) => {
    switch (data) {
        case 420:
            return true;
        case 440:
            return true;
        default:
            return false;
    }
}

export const isArenaOrAram = (data) => {
    return isArena(data) || isAram(data)
}

export const noPositionCheck = (queue_id) => {
    if (isSwarm(queue_id)) {
        return false;
    }
    if (isArena(queue_id)) {
        return false;
    }
    switch (queue_id) {
        case 1300:
            return false;
        case 450 :
            return false;
        case 900 :
            return false;
        case 1900 :
            return false;
        case 720 :
            return false;
        case 1700:
            return false;
        default:
            return true;
    }
}


export const changeToIntlRegion = (lang) => {
    switch (lang) {
        case 'KOR' :
            return 'ko';
        case "ENG" :
            return 'en';
        case LANGUAGE_FOR_OS.ko:
            return 'ko'
        case LANGUAGE_FOR_OS["ko-KR"] :
            return 'ko'
        case LANGUAGE_FOR_OS.VI:
            return 'vi'
        case LANGUAGE_FOR_OS.ZHTW:
            return 'zh-tw'
        case LANGUAGE_FOR_OS.ZHCN:
            return 'zh-cn'
        case LANGUAGE_FOR_OS.DA:
            return 'de'
        case LANGUAGE_FOR_OS.RU:
            return 'ru'
        case LANGUAGE_FOR_OS.DE:
            return 'de'
        case LANGUAGE_FOR_OS.ES:
            return 'es'
        case LANGUAGE_FOR_OS.FR:
            return 'fr'
        case LANGUAGE_FOR_OS.HU:
            return 'hu'
        case LANGUAGE_FOR_OS.IT:
            return 'it'
        case LANGUAGE_FOR_OS.JA:
            return 'ja'
        case LANGUAGE_FOR_OS.PL:
            return 'pl'
        case LANGUAGE_FOR_OS.PT:
            return 'pt'
        case LANGUAGE_FOR_OS.TR:
            return 'tr'
        case LANGUAGE_FOR_OS.RO:
            return 'ro'
        case LANGUAGE_FOR_OS.TH:
            return 'th'
        case LANGUAGE_FOR_OS.FI:
            return 'fi'
        case LANGUAGE_FOR_OS.NL:
            return 'nl'
        case LANGUAGE_FOR_OS.NORWAY:
            return 'no'
        case LANGUAGE_FOR_OS.SR:
            return 'sr'
        default:
            return 'en'
    }
}


const calculateIntlTime = (DivisionList, formatter, time) => {
    if (time < 1 || !isFinite(time)) {
        return formatter.format(-1, DivisionList[0].name);
    }
    const currentTime = new Date();
    if (currentTime - time < 60000) {
        return formatter.format(-1, DivisionList[1].name);
    }
    let duration = (time - new Date()) / 1000


    for (let i = 0; i <= DivisionList.length - 1; i++) {
        const division = DivisionList[i]
        if (Math.abs(duration) < division.amount || i === DivisionList.length - 1) {
            return formatter.format(Math.ceil(duration), division.name)
        }
        duration /= division.amount
    }
}


export const timeFormatter = (time, lang) => {
    const formatter = new Intl.RelativeTimeFormat(changeToIntlRegion(lang), {
        numeric: 'always'
    })


    const DivisionList = [
        {amount: 60, name: 'seconds'},
        {amount: 60, name: 'minutes'},
        {amount: 24, name: 'hours'},
        {amount: 7, name: 'day'},
        {amount: 4.34524, name: 'weeks'},
        {amount: 12, name: 'months'},
        {amount: Number.POSITIVE_INFINITY, name: 'years'}
    ];

    return calculateIntlTime(DivisionList, formatter, time)

}

export const intlMinuetsTimeFormatter = (time, lang) => {
    const formatter = new Intl.RelativeTimeFormat(changeToIntlRegion(lang), {
        numeric: 'always'
    })


    const DivisionList = [
        {amount: 60, name: 'seconds'},
        {amount: 60, name: 'minutes'},
        {amount: 24, name: 'hours'},
        {amount: 7, name: 'day'},
    ];

    return calculateIntlTime(DivisionList, formatter, time)
}

export const changeListBySort = (value, sortData, champions) => {
    if (Array.isArray(value)) {
        const name = sortData.find((data) => data.value === 'champion_name_kr' || data.value === 'champion_name_en');
        if (name) {
            const mapped = value.map((data) => {
                const find = champions.find((item) => Number(item.champion_id) === Number(data.champion_id));
                if (find && find?.champion_name_kr) {
                    return {
                        ...data,
                        [name.value]: find[name.value],
                    }
                }
                return {
                    ...data,
                    [name.value]: "All",
                }
            });

            return sortChampData(mapped, [...sortData]);
        } else {
            return sortChampData(value, [...sortData]);
        }


    }
    return [];
}


export const currentLangToRegion = (lang) => {
    switch (lang) {
        case "KOR":
            return "ko_KR"
        case "ENG":
            return "en_US"
        default:
            return "en_US"
    }
}


export function calculateEnglishOrdinal(n) {
    let ord = 'th';

    if (n === 1) {
        ord = 'st';
    } else if (n === 2) {
        ord = 'nd';
    } else if (n === 3) {
        ord = 'rd';
    }

    return ord;
}

export function patchOrder(patchName) {
    switch (patchName) {
        case 'buff' :
            return 0;
        case 'nerf' :
            return 1;
        case 'fix' :
            return 2;
        default:
            return 999;
    }

}

export const multiplyNumber = (number, multiply, options) => {
    const fixedDigit = typeof options?.fixed === 'number' ? options?.fixed : 1;
    const result = (Number(number) * multiply).toFixed(fixedDigit);
    if (options?.disableFixed) {
        return Math.trunc(Number(result));
    }
    if (result === '100.0') {
        return 100;
    } else if (result === '0.0') {
        return 0;
    }
    return result;
}

const getOldTier = (currentTier) => {
    if (currentTier === 'Emerald+') {
        return 'Platinum+'
    }
    return currentTier
}
const getNewTier = (currentTier) => {
    if (currentTier === 'Platinum+') {
        return 'Emerald+'
    }
    return currentTier
}
export const getReplaceTier = (version, tier) => {
    if (!oldVersion.includes(version)) {
        return getNewTier(tier);
    } else {
        return getOldTier(tier);
    }
}


export const romanNumberTrans = (number) => {
    if (number === 'IV') {
        return 4;
    } else if (number === 'III') {
        return 3;
    } else if (number === 'II') {
        return 2;
    } else if (number === 'I') {
        return 1;
    } else {
        return ""
    }
}

export const setLaneIndex = (lane) => {
    switch (lane) {
        case 'Top':
            return 4
        case 'Jungle':
            return 3
        case 'Middle':
            return 2
        case 'Bot':
            return 1
        case 'Supporter':
            return 0
        default:
            return 99
    }
};


export const mapLane = (array) => {
    try {
        return array.map((data) => ({...data, laneIndex: setLaneIndex(data.position)}));
    } catch (e) {
        console.log(e);
        return array;
    }
}


export const sortLaneMap = (array) => {
    const laneMap = mapLane(array);
    const sort = [{value: "laneIndex", sortType: false}]
    return sortChampData(laneMap, sort);
}


export const hundredCheck = (value) => {
    if (Number(value) === 100) {
        return 100;
    }
    if (Number(value) === 0) {
        return 0;
    }
    return value;
}


export const getWinningStreak = (arr) => {
    try {
        let streak = 0;
        let biggestStreak = 0;

        arr.forEach((data) => {
            if (data === 1) {
                streak += 1;
                if (streak > biggestStreak) {
                    biggestStreak = streak
                }
            } else {
                streak = 0;
            }
        })
        return biggestStreak;
    } catch (e) {
        return 0;
    }
}

export const getLastWinningStreak = (arr) => {
    try {
        let streak = 0;

        arr.forEach((data) => {
            if (data === 0) {
                return;
            }
            if (data === 1) {
                streak += 1;
            } else {
                streak = 0;
            }
        })
        return streak;
    } catch (e) {
        return 0;
    }
}


export const getArgmentImagePath = (argment) => {
    const image = argment.iconLarge;
    const v1Path = 'https://raw.communitydragon.org/13.22/game/assets/ux/cherry/augments/icons/';
    const v2Path = "https://raw.communitydragon.org/latest/game/"
    switch (argment.id) {
        case 93:
            return v1Path + "warmuproutine_large.2v2_mode_fighters.png";
    }
    return v2Path + image
}

export const checkDefaultTag = (value, region) => {
    const defaultTag = regionList.find((data) => data.title === region);
    return defaultTag === value;
}

export function monthsForLocale(localeName = 'en-US', monthFormat = 'long', time) {
    try {
        const format = new Intl
            .DateTimeFormat(changeToIntlRegion(localeName), {month: monthFormat}).format;
        return format(new Date(time));
    } catch (e) {
        const format = new Intl
            .DateTimeFormat('en-US', {month: 'short'}).format;
        return format(new Date(time));
    }
}

export const countryName = (localName, countryName) => {
    try {
        const intl = new Intl.DisplayNames(changeToIntlRegion(localName), {type: 'region'});
        return intl.of(countryName)
    } catch (e) {
        return '-'
    }
}

export const isOtherDefaultTag = (region, tag) => {
    const currentRegion = regionList.find((data) => data.serverName === region);
    const result = regionList.find((data) => data.serverName !== currentRegion.serverName && data.tagName.toLowerCase() === tag.toLowerCase())
    return !!result;
}


export const changePositionNameToImgPositionName = (lane) => {
    let result = '';
    switch (lane) {
        case 'Top':
            result = 'top';
            break;
        case 'Mid':
            result = 'middle';
            break;
        case 'Jungle':
            result = 'jungle';
            break;
        case 'Bot':
            result = 'bot';
            break;
        case 'Support':
            result = 'supporter';
            break;
        default:
            result = lane;
            break;
    }
    return result;
};

export const getRegionColor = (region) => {
    let color = 'transparent';
    if (region === 'NA') color = '#70558E';
    if (region === 'EUN') color = '#3B4D82';
    if (region === 'EUW') color = '#4979A9';
    if (region === 'KR') color = '#0DBAAE';
    if (region === 'JP') color = '#DF7299';
    if (region === 'BR') color = '#69BE74';
    if (region === 'LAN') color = '#DECF49';
    if (region === 'LAS') color = '#DB9342';
    if (region === 'OCE') color = '#55B4D2';
    if (region === 'TR') color = '#C95D58';
    if (region === 'RU') color = '#B84984';
    if (region === 'PH') color = '#8986DB';
    if (region === 'SG') color = '#9DB717';
    if (region === 'TH') color = '#A743AE';
    if (region === 'TW') color = '#A47C10';
    if (region === 'VN') color = '#B26441';
    if (region === 'ME') color = '#1B7C64'
    return color;
}

export const getOnlyDecimal = (number, length = 1) => {
    const decimal = number % 1;
    return Math.floor(decimal * Math.pow(10, length))
}


export const replaceBlockUnicode = (string) => {
    if (typeof string === 'string') {
        return string.replaceAll(/[\u2066\u2069]/g, '')
    }
    return string
}

export const getAIScoreColor = (value) => {
    if (value >= 75) {
        return AI_SCORE_PERFECT
    } else if (value >= 50) {
        return AI_SCORE_GOOD;
    } else if (value >= 25) {
        return AI_SCORE_BAD;
    }
    return AI_SCORE_VERY_BAD
}

export const getAIScoreTextColor = (value) => {
    if (value >= 75) {
        return AI_SCORE_PERFECT_TEXT
    } else if (value >= 50) {
        return AI_SCORE_GOOD_TEXT;
    } else if (value >= 25) {
        return AI_SCORE_BAD_TEXT;
    }
    return AI_SCORE_VERY_BAD_TEXT
}


export const calcKda = ({kills, assists, deaths}) => {
    const calValue = (value, deaths) => {
        return (value / deaths).toFixed(2);
    }

    if (kills === 0 && assists === 0) {
        return "0.00";
    } else if (deaths === 0) {
        return calValue(kills + assists, 1);
    } else {
        return calValue(kills + assists, deaths);
    }
}


export const shortChampionIdsAndText = (championId) => {
    switch (Number(championId)) {
        case 79:
            return ['글가']
        case 104:
            return ['그브']
        case 20:
            return ['월럼프', '누누와월럼프']
        case 119:
            return ['드븐']
        case 236:
            return ['루샨']
        case 11:
            return ['마이']
        case 25:
            return ['몰가']
        case 106:
            return ['볼베']
        case 53:
            return ['블츠', '블크']
        case 5:
            return ['짜오']
        case 32:
            return ['무무']
        case 136:
            return ['아우솔', '아우렐리온솔']
        case 51:
            return ['케틀']
        case 18:
            return ['트리', '트타']
        case 4:
            return ['트페', '트위스티드페이트']
        case 80:
            return ['판테,빵테,팡테']
        case 74:
            return ['딩거']
        default:
            return [];
    }
}


export const isArabic = (text) => {
    const arabic = /[\u0600-\u06FF]/;
    return arabic.test(text);
}


export const getIngamePositionObj = (basic) => {
    return basic.reduce((sum, data) => {
        sum[data.position].push(data)
        return sum;
    }, {
        Top: [], Middle: [], Jungle: [], Bot: [], Supporter: [],
    });
}
// .tier_color {
// &.challenger {
//         color: #EE7A00;
//     }
//
// &.grandmaster {
//         color: #C23449;
//     }
//
// &.master {
//         color: #863DA1;
//     }
//
// &.diamond {
//         color: #2898C9;
//     }
//
// &.emerald {
//         color: #12C3C9;
//     }
//

export const getTierColor = (tier) => {
    switch (tier.toLowerCase()) {
        case 'unrank': {
            return '#7F7F7F'
        }
        case 'iron': {
            return '#5A5959'
        }
        case 'bronze': {
            return '#876B26'
        }
        case 'silver': {
            return '#6A79AB'
        }
        case 'gold': {
            return '#BA8D26'
        }
        case 'platinum': {
            return '#1DA794';
        }
        case 'emerald': {
            return '#12C3C9'
        }
        case 'diamond': {
            return '#2898C9'
        }
        case 'master': {
            return '#863DA1'
        }
        case 'grandmaster': {
            return '#C23449'
        }
        case 'challenger': {
            return '#EE7A00'
        }
        default:
            return '#5A5959';
    }
}

export const eventNameTrans = (name) => {
    if (name === 'JapaneseKoreanUG') {
        return 'UG'
    }
    return name
}

export const createMatchDescription = ({item}) => {

    if (typeof item !== 'object') {
        return {
            description: "",
            match_id: 0,
            name: "",
            platform_id: "",
            queue_id: "",
            riot_name: "",
            riot_tag: "",
            start_timestamp: "",
            status: "",
            team_al: "",
        }
    }

    return {
        description: item?.description ?? "",
        match_id: item?.match_id ?? "",
        name: item?.name ?? "",
        platform_id: item?.platform ?? "",
        queue_id: item?.queue_id ?? "",
        riot_name: item?.riot_name ?? "",
        riot_tag: item?.riot_tag ?? "",
        start_timestamp: item?.start_timestamp ?? "",
        status: item?.status ?? "",
        team_al: item?.team_al ?? "",
    }
}

export const replaceOldVersionRuneName = (name) => {
    return name.replace('perk-images/Styles/', 'perks/styles/')
}

export const getTierShortName = (name) => {
    // ['IRON', 'BRONZE', 'SILVER', 'GOLD', 'PLATINUM', 'EMERALD', 'DIAMOND', 'MASTER', 'GRANDMASTER', 'CHALLENGER'];
    switch (name) {
        case 'IRON' :
            return 'I'
        case 'BRONZE' :
            return 'B'
        case 'SILVER' :
            return 'S'
        case 'GOLD' :
            return 'G'
        case 'PLATINUM' :
            return 'P'
        case 'EMERALD' :
            return 'E'
        case 'DIAMOND' :
            return 'D'
        case 'MASTER' :
            return 'M'
        case 'GRANDMASTER' :
            return 'GM'
        case 'CHALLENGER' :
            return 'C'
        default:
            return name
    }
}

export const isDetailChartRender = (match) => {
    try {
        const queue_id = match.match_basic_dict.queue_id;

        if (isAram(queue_id)) {
            return false;
        }

        if(isArena(queue_id)) {
            return false
        }

        const time = match.time_analysis
        const time_events = time.concat_events_dict;
        const time_parti = time.concat_participants_dict
        const time_seq = time.concat_seq_dict;

        if (_.isEmpty(time_events) || _.isEmpty(time_parti) || _.isEmpty(time_seq)) {
            return false;
        }

        return true;
    } catch (e) {
        return false
    }
}
export const openStoryRegion = (region) => {

    if(region.toUpperCase() === 'KR') {
        return true;
    }

    if(region.toUpperCase() === 'EUW') {
        return true;
    }

    if(region.toUpperCase() === 'JP') {
        return true;
    }

    return false;
}