import React from 'react';
import {FlexBox} from "../../ui/styled";
import styled, {useTheme} from "styled-components";
import {Trans, useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import {useSelector} from "react-redux";
import {openStoryRegion} from "../../../function";


const DarkModeText = styled.span`
  font-size: 12px;
  white-space: pre-wrap;
  line-height: 17px;
  font-weight: 700;
  color: ${props => props.theme.color.dark_100};
  margin: 0 4px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-left: 5px;
`;

const ExtraText = styled.span`
  font-size: 12px;
  line-height: 17px;
  font-weight: bold;
  color: ${props => props.theme.color.red_600};
`;
const GreenText = styled.span`
  color: #0DBAAE;
`;

const Wrapper = styled(FlexBox)`
  cursor: pointer;
`;
const BlackText = styled.span`
  text-align: right;
  color: ${props => props.theme.color.dark_100};
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  margin-top: 4px;
  white-space: pre-wrap;
  strong:first-of-type {
    color:#8775FB;
  }
  strong:last-of-type {
    color: #EE6787;
  }
`;

const EmptyWrapper = styled.div`
    height: 44px;
`;

const HomeNotice = () => {
    const {t} = useTranslation();
    const theme = useTheme();
    const history = useHistory();
    const region = useSelector((state) => state.region.result);
    const currentLang = useSelector((state) => state.translation.lang);


    if(!openStoryRegion(region)){
        return <EmptyWrapper/>
    }

    return (
        <Wrapper justifyContent={'flex-end'} height={'auto'} width={'736px'}
                 alignItems={'flex-start'} margin={'0 0 10px 0'} onClick={() => history.push('/story')}>
            <ButtonWrapper>
                <BlackText>
                   <Trans i18nKey={'homeNotice.noticeTitle'}/>
                </BlackText>
            </ButtonWrapper>
        </Wrapper>
    )
};

export default HomeNotice;