import React from 'react';
import {useQueryClient} from "@tanstack/react-query";
import {useTranslation} from "react-i18next";
import {Loading} from "../common";
import styled from "styled-components";
import {useSelector} from "react-redux";

const LoadingWrapper = styled.div`
  background: ${props => props.theme.color.site_main_color};
  height: 300px;
  border-radius: 6px;
`;

// champions: [],
//     spells: {},
// gameVersion : [],
//     runes: [],
//     runeShards: runeShards,
//     items: {},
// aiData: {
//     tier_boundary_solo :[],
// },
// country: "",
//     season:[],
//     streamerTag:[],
//     challenges:{},
// currentSeason: 17,
//     tooltip: false,
//     itemInfo: {
//     mythic_item_lst: [],
//         legendary_item_lst:[],
//         ornn_item_lst:[],
// },


const CheckDataLayer = ({children}) => {
    const {i18n} = useTranslation()
    const queryClient = useQueryClient();
    // const seasonList = queryClient.getQueryData(['seasonList'])
    // const tierBoundary = queryClient.getQueryData(['tierBoundary'])
    // const oldSeason = queryClient.getQueryData(['oldSeason'])
    // const itemInfo = queryClient.getQueryData(['itemInfo'])
    // const augments = queryClient.getQueryData(['augments', i18n.language])
    // const rune = queryClient.getQueryData(['appRune', i18n.language])
    // const spell = queryClient.getQueryData(['appSpell', i18n.language])
    // const item = queryClient.getQueryData(['appItem', i18n.language])
    const {season,items,spells,runes,argumentsInfo,itemInfo,oldSeason,aiData} = useSelector((state) => state?.imageStore);


    if(season?.length < 1) {
        return (<LoadingWrapper>
            <Loading/>
        </LoadingWrapper>)
    }
    if(oldSeason?.length < 1) {
        return (<LoadingWrapper>
            <Loading/>
        </LoadingWrapper>)
    }
    // if(argumentsInfo?.length < 1) {
    //     return (<LoadingWrapper>
    //         <Loading/>
    //     </LoadingWrapper>)
    // }
   if(runes?.length < 1)  {
       return (<LoadingWrapper>
           <Loading/>
       </LoadingWrapper>)
   }

   if(Object.keys(spells)?.length < 1) {
       return (<LoadingWrapper>
           <Loading/>
       </LoadingWrapper>)
   }
    if(Object.keys(items)?.length < 1) {
        return (<LoadingWrapper>
            <Loading/>
        </LoadingWrapper>)
    }


    return children
};

export default CheckDataLayer;