import React from 'react';
import styled from "styled-components";
import {useSelector} from "react-redux";

const Wrapper = styled.div`
`;

const RuneImage = styled.img`
`;

const JustRuneImage = ({width,height,image}) => {
    const version = useSelector((state) => state.gameVersion.version);
    return (
            <Wrapper>
                <RuneImage
                    width={width}
                    height={height}
                    src={`${process.env.REACT_APP_IMG}${version}/img/${image?.replace('.png',"")}__56.webp`}
                    alt={'rune'}
                    onError={(e) => e.target.src = "/images/icon_non_item.svg"}
                    className={'just-rune'}
                />
            </Wrapper>
    );
};

export default JustRuneImage;
