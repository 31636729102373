import React, {useMemo} from "react";

const KoreanAd = () => {

    const adState = useMemo(() => {
        const getStoreValue = localStorage.getItem('summonerBanner');
        const setBannerValue = getStoreValue === '1' ? '2' : '1';
        localStorage.setItem('summonerBanner', String(setBannerValue))
        if(getStoreValue !== '1' && getStoreValue !== '2') {
            return '1';
        }
        return getStoreValue
    }, []);

    //
    // const [adState, setAdState] = useState(() => {
    //     const random = _.random(0, 100);
    //     // setAd(random)
    //     return random
    // });


    if (adState === '1') {
        return (
            <a href={'https://bit.ly/4h5PC4Z'} target={'_blank'}>
                <img src={'./images/spanish_banner.png'} width={'100%'} height={52} alt={''}
                     className={'add_to_spanish'}/>
            </a>
        )
    }


    // return (
    //
    //     <a href={'https://chzzk.naver.com/3dadd937e0e47035b3c8c377815ce88a/community/detail/16202072'}
    //        target={'_blank'}>
    //         <img src={'./images/tan_ck_banner.png'} width={'100%'} height={52} alt={''}
    //              className={'add_to_tan'}/>
    //     </a>
    // )

    // return (
    //     <a href={'https://ch.sooplive.co.kr/podone1104/post/138536371'} target={'_blank'}>
    //         <img src={'./images/podo_kr_title_1.png'} width={'100%'} height={52} alt={''}
    //              className={'add_to_podo'}/>
    //     </a>
    // )
    return (
        <a href={'https://bit.ly/3ZFluY1'} target={'_blank'}>
            <img src={'./images/ad_geng_kr.png'} width={'100%'} height={52} alt={''}
                 className={'add_to_Gen'}/>
        </a>
    )
}
export default KoreanAd