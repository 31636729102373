import React, {useCallback, useLayoutEffect, useMemo} from 'react';
import styled, {useTheme} from "styled-components";
import ChampionPassiveImage from "../../../common/ChampionPassiveImage";
import ChampionSkillImage from "../../../common/ChampionSkillImage";
import {useTranslation} from "react-i18next";
import useQuery from "../../../../common/hooks/useQuery";
import {Route, Switch, useHistory} from "react-router-dom";
import {ErrorBoundary} from "react-error-boundary";
import useChampionFunc from "../../../../common/hooks/useChampionFunc";
import {useDispatch, useSelector} from "react-redux";
import useChampBuildFunc from "../../../../common/hooks/useChampBuildFunc";
import CRTTier
    from "../../../Champions/ChampionsContent/ChampionsRank/ChampionsRankingTable/ChampionsRankingTableContent/CRTTier";
import {threedigits} from "../../../../function";
import {SET_ARAM_TIP_INDEX} from "../../../../store/reducer/champions";
import {dark, light} from "../../../../common/theme";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: ${props => props.theme.color.site_main_color};
  border: 1px solid ${props => props.theme.color.default_border_color};
  border-radius: 6px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`;


const ChampionImage = styled.img`
  margin: -3px;
`;

const ChampionImageWrapper = styled.div`
  border-radius: 6px;
  overflow: hidden;
  margin-right: 24px;
  height: fit-content;
  z-index: 99;
`;


const ChampionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  padding: 15px;
`;

const ChampionSpellWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;

const SpellAndNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 91px;

`;
const ChampionNameText = styled.span`
  font-size: 22px;
  font-weight: bold;
  color: ${props => props.theme.color.dark_100};
`;

const BuildText = styled.span`
  font-size: 14px;
  margin-left: 12px;
  color: ${props => props.theme.color.dark_200};
  font-weight: 500;
`;

const ChampionNameWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const PatchWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const BuildInfoText = styled.span`
  font-size: 12px;
  white-space: pre-wrap;
  text-align: right;
  line-height: 15px;
  color: ${props => props.theme.color.dark_300};
`;


const InfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;

const BuildInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  padding: 15px;
`;

const BuildInfoBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const ChampionsDetailHeader = ({champion, version, patchHistory, skillList}) => {
    const {t, i18n} = useTranslation();
    const isKorean = useMemo(() => ["ko", "ko-KR"].includes(i18n.language), [i18n.language])
    const buildFunc = useChampBuildFunc();
    const buildInfo = buildFunc.currentBuild;

    const buildVersion = useSelector((state) => state.champions.championVersions);
    const query = useQuery();
    const history = useHistory();
    const {getChampionName} = useChampionFunc()


    const laneTrans = useCallback((data) => {
        switch (data) {
            case "top": {
                return t('championAnalyze.top')
            }
            case "jungle" : {
                return t('championAnalyze.jungle')
            }
            case "middle" : {
                return t('championAnalyze.mid')
            }
            case "bot" : {
                return t('championAnalyze.bot')
            }
            case "supporter": {
                return t('championAnalyze.support')
            }
            case "aram": {
                return t("championsHistory.title.aram")
            }
            case "singular": {
                return "단일 챔피언"
            }
            default:
                return ""
        }
    }, []);

    const tierTrans = useCallback((data) => {
        switch (data.trim()) {
            case "Emerald": {
                return t('gameAnalytics.emerald')
            }
            case "Platinum": {
                return t('gameAnalytics.platinum')
            }
            case "Diamond" : {
                return t('gameAnalytics.diamond')
            }
            case "Master" : {
                return t('gameAnalytics.master')
            }
            case "Grandmi18naster" : {
                return t('gameAnalytics.grandmaster')
            }
            case "Challenger": {
                return t('gameAnalytics.challenger')
            }
            default:
                return ""
        }
    }, []);

    const tier = useMemo(() => {
        try {
            if (history.location.pathname.split('/')[4] !== 'aram') {
                const tier = query.get('tier');
                return tier ? `${tierTrans(tier)}` : `${tierTrans("Emerald")}`;
            }
            return "";
        } catch (e) {
            return "";
        }
    }, [window.location.href, i18n.language]);

    const currentBuild = useMemo(() => {
        try {
            const lane = history.location.pathname.split('/')[4];
            if (lane) {
                return laneTrans(lane);
            } else {
                return "";
            }
        } catch (e) {
            return "";
        }
    }, [window.location.href, i18n.language]);

    const currentVersion = useMemo(() => {
        try {
            const queryVersion = query.get('version');
            return queryVersion ? queryVersion : buildVersion[0];
        } catch (e) {
            return "";
        }

    }, [window.location.href, i18n.language, buildVersion])


    return (
        <Wrapper>
            <Row>

                <ChampionWrapper>
                    <ChampionImageWrapper>
                        <ChampionImage
                            width={97}
                            height={97}
                            src={`${process.env.REACT_APP_IMG}${version}/img/champion/${champion?.image_name}__100.webp`}
                            onError={(e) => e.target.src = "/images/icon_non_item.svg"}
                        />
                    </ChampionImageWrapper>
                    <SpellAndNameWrapper>
                        <ChampionNameWrapper>
                            <ChampionNameText>{champion?.champion_name}</ChampionNameText>
                            <ErrorBoundary fallback={null}>
                                {history.location.pathname.split('/')[3] === 'build' &&
                                    <BuildText>{currentBuild} {t("championDetail.build")}, {tier ? tier + "," : ""} {t('gameAnalytics.patch')} {currentVersion}</BuildText>}
                            </ErrorBoundary>
                        </ChampionNameWrapper>
                        <InfoWrapper>

                            <ChampionSpellWrapper>
                                <ChampionPassiveImage
                                    reverse={true}
                                    margin={'0 5px 0 0'}
                                    version={version}
                                    width={36}
                                    height={36}
                                    img={skillList?.passive?.image?.full}
                                    tooltip={`<span style="font-weight: 500; color: #46B9AA;">${skillList?.passive?.name}</span>\n${skillList?.passive?.description}`}
                                />
                                <ChampionSkillImage
                                    reverse={true}
                                    margin={'0 5px 0 0'}
                                    width={36}
                                    height={36}
                                    spell={skillList?.spells[0]}
                                    version={version}
                                    absoluteText={'Q'}
                                    img={skillList?.spells[0]?.image?.full}

                                />
                                <ChampionSkillImage
                                    reverse={true}
                                    margin={'0 5px 0 0'}
                                    width={36}
                                    height={36}
                                    spell={skillList?.spells?.[1]}
                                    version={version}
                                    absoluteText={'W'}
                                    img={skillList?.spells[1]?.image?.full}
                                />
                                <ChampionSkillImage
                                    reverse={true}
                                    margin={'0 5px 0 0'}
                                    width={36}
                                    height={36}
                                    spell={skillList?.spells?.[2]}
                                    tooltip={`${champion.spells?.[2]?.spell_name_kr}\n${champion.spells?.[2]?.tooltip}`}
                                    version={version}
                                    absoluteText={'E'}
                                    img={skillList?.spells[2]?.image?.full}
                                />
                                <ChampionSkillImage
                                    reverse={true}
                                    margin={'0 0 0 0'}
                                    width={36}
                                    height={36}
                                    spell={skillList?.spells?.[3]}
                                    tooltip={`${champion.spells?.[3]?.spell_name_kr}\n${champion.spells?.[3]?.tooltip}`}
                                    version={version}
                                    absoluteText={'R'}
                                    img={skillList?.spells[3]?.image?.full}
                                />
                            </ChampionSpellWrapper>
                        </InfoWrapper>
                    </SpellAndNameWrapper>
                </ChampionWrapper>
                {history.location.pathname.split('/')[3] === 'build' &&
                    <BuildInfoWrapper>
                        <BuildInfoBox>
                            <BuildOverview buildInfo={buildInfo}/>
                        </BuildInfoBox>
                        <BuildInfoText>
                            {t('championDetailBuild.headerInfo', {
                                currentBuild,
                                champion: getChampionName(champion),
                                currentVersion,
                                tier,
                            })}
                        </BuildInfoText>
                    </BuildInfoWrapper>}
            </Row>
            <Switch>
                <Route exact path={`/champions/:champName/build/aram`}>
                    <AramTipItem/>
                </Route>
            </Switch>
        </Wrapper>
    );
};

const OverviewWrapper = styled.div`
  display: flex;
  flex-direction: row;

  .light + .light {
    border-left: 1px solid ${props => light.gray_200};
  }

  .dark + .dark {
    border-left: 1px solid ${props => dark.gray_200};
  }
`;

const RankText = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: ${props => props.theme.color.dark_100};
`;

const RankMoreBase = styled.span`
  font-size: 12px;
  font-weight: 500;
  color: ${props => props.theme.color.dark_200};
  margin-left: 1px;
`;


const BuildOverview = ({buildInfo}) => {
    const {t} = useTranslation();
    const champions = useSelector((state) => state.imageStore.champions);


    if (!buildInfo) return null

    return (
        <OverviewWrapper>
            <BuildOverviewItem title={t('championDetailBuild.tier')} isShort={true}>
                <CRTTier
                    tier={buildInfo?.champion_tier}
                    height={30}
                    width={30}
                />
            </BuildOverviewItem>
            <BuildOverviewItem title={t('championDetailBuild.ranks')}>
                <RankText>{buildInfo.rank}{t("summoner.graph_grade", {
                    count: buildInfo.rank,
                    ordinal: true
                })}<RankMoreBase> / {buildInfo.lane_cnt_all ? buildInfo.lane_cnt_all : champions.length}</RankMoreBase></RankText>
            </BuildOverviewItem>
            <BuildOverviewItem title={t('summoner.mastery.winRate')}>
                <RankText>{(buildInfo.win_rate * 100).toFixed(1)}<RankMoreBase>%</RankMoreBase></RankText>
            </BuildOverviewItem>

            <BuildOverviewItem title={t('championDetailBuild.pickRate')}>
                <RankText>{(buildInfo.pick_rate * 100).toFixed(1)}<RankMoreBase>%</RankMoreBase></RankText>
            </BuildOverviewItem>

            <BuildOverviewItem title={t('championDetailBuild.banRate')}>
                <RankText>{(buildInfo.ban_rate * 100).toFixed(1)}<RankMoreBase>%</RankMoreBase></RankText>
            </BuildOverviewItem>

            <BuildOverviewItem title={t('mastery.games')}>
                <RankText>{threedigits(buildInfo.games)}</RankText>
            </BuildOverviewItem>
        </OverviewWrapper>
    )
}


const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: ${props => props.isShort ? '65px' : '80px'};
  padding: 0 10px;
  height: 55px;

`;
const Title = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: ${props => props.theme.color.dark_200};
`;
const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
`;
const BuildOverviewItem = ({title, isShort, children}) => {
    const theme = useTheme();
    return (
        <ItemWrapper isShort={isShort} className={theme.color.theme}>
            <Title>
                {title}
            </Title>
            <Content>
                {children}
            </Content>
        </ItemWrapper>
    )
}

const AramTipBox = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  background-color: ${props => props.theme.color.white_200};
  margin: 0 10px 15px 15px;
  padding: 10px;
`;
const TitleText = styled.span`
  font-size: 14px;
  font-weight: bold;
  color: ${props => props.theme.color.green_100};
  margin-left: 5px;
`;

const TipText = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: ${props => props.theme.color.dark_200};
`;
const AramTipRow = styled(Row)`
  align-items: center;
  margin-bottom: 4px;
`;
const AramTipItem = () => {
    const aramTip = useSelector((state) => state?.champions?.aramTip);

    if (!Array.isArray(aramTip)) {
        return null
    }

    if (aramTip.length === 0) {
        return null
    }


    return (
        <AramTipContent/>
    )
}

const AramTipContent = () => {
    const aramTip = useSelector((state) => state?.champions?.aramTip);
    const aramTipIndex = useSelector((state) => state?.champions?.aramTipIndex);
    const dispatch = useDispatch();
    const {t} = useTranslation();


    useLayoutEffect(() => {
        const lastIndex = aramTip.length - 1;
        const nextIndex = aramTipIndex + 1;
        if (nextIndex <= lastIndex) {
            dispatch({
                type: SET_ARAM_TIP_INDEX,
                data: nextIndex
            })
        } else if (nextIndex > lastIndex) {
            dispatch({
                type: SET_ARAM_TIP_INDEX,
                data: 0
            })
        }
    }, []);

    return (
        <AramTipBox>
            <AramTipRow>
                <img src={'./images/icn_light_bulb.svg'} width={18} height={18} alt={''}/>
                <TitleText>{t('header.navAram')} Tip</TitleText>
            </AramTipRow>
            <TipText>
                {aramTip[aramTipIndex]}
            </TipText>
        </AramTipBox>
    )
}


export default React.memo(ChampionsDetailHeader);
