import React, {useEffect, useMemo, useState} from 'react';
import SummonerMatchesProvider from "../../compound/SummonerMatches";
import {ColumnBox, FlexBox} from "../../ui/styled";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import UseSetCanonical from "../../../common/hooks/useSetCanonical";
import styled from "styled-components";
import useSummerMatch from "../../../common/hooks/useSummerMatch";
import {GET_SUMMONER_INFO_REQUEST} from "../../../store/reducer/summoner";
import useTitleAndDescription from "../../../common/hooks/useTitleAndDescription";
import {useParams} from "react-router-dom";
import {MatchListContainer} from "../../../styled/SummonerStyle";
import NewsChat from "../../news/newsChat";


const SpaceDiv = styled.div`
  margin-bottom: 10px;
`;
const AdBox = styled.div`
  .med_rect_atf {
    height: 250px;
  }
`;

const SideBarAd = styled.div`
`;

const Story = ({storyApi}) => {
    const summoner = useSelector((state) => state.summoner.summoner);
    const {t} = useTranslation();
    const {userName, region,} = useParams();
    const {filterMatch} = useSummerMatch({customList: storyApi.matchList.matchList});
    const {getSummonerInfoDone} = useSelector((state) => state.summoner);
    const dispatch = useDispatch();
    const currentSeason = useSelector((state) => state.imageStore.currentSeason);
    const currentUserName = useMemo(() => {
        if (userName && summoner) {
            return summoner?.summoner_basic_info_dict?.riot_id_name;
        } else {
            return userName;
        }
    }, [summoner, userName]);

    const currentTagName = useMemo(() => {
        if (userName && summoner) {
            return summoner?.summoner_basic_info_dict?.riot_id_tag_line
        } else {
            return '#';
        }
    }, [summoner, userName])

    const currentProName = useMemo(() => {
        if (userName && summoner) {
            return summoner?.summoner_basic_info_dict?.pro_streamer_info_dict?.name
        } else {
            return '';
        }
    }, [summoner, userName])

    useTitleAndDescription({
        description: t('header.titleSummonerDescription', {
            summoner: decodeURI(currentUserName),
            tag: currentTagName,
            pro: currentProName,
            location: region,
        }),
        title: t('header.titleSummoner', {
            summoner: decodeURI(currentUserName),
            tag: currentTagName,
            pro: currentProName,
        })
    })
    UseSetCanonical();
    const summonerStat = useSelector((state) => state.summoner.summonerStatus);
    const rankTier = useMemo(() => {
        try {
            return summoner.season_tier_info_dict.ranked_solo_5x5
        } catch (e) {
            return {tier: "", division: 1}
        }
    }, [summoner]);
    const flexTier = useMemo(() => {
        try {
            return summoner.season_tier_info_dict.ranked_flex_sr
        } catch (e) {
            return {tier: "", division: 1}
        }
    }, [summoner]);


    useEffect(() => {
        if (!getSummonerInfoDone) {
            dispatch({
                type: GET_SUMMONER_INFO_REQUEST,
                data: {
                    summoner: summoner,
                    region: region,
                    currentSeason: currentSeason,
                }
            })
        }
    }, []);

    return (
        <SummonerMatchesProvider>
            <FlexBox margin={'10px 0 0 0'} alignItems={'flex-start'}>
                <ColumnBox>
                    <SpaceDiv>
                        <SummonerMatchesProvider.Rank
                            topPercent={rankTier.ranking_rate >= 0.0001 ? rankTier.ranking_rate.toFixed(4) : 0.0001}
                            rank={rankTier.ranking}
                            tier={rankTier.tier}
                            division={rankTier.division}
                            title={t('summoner.rankedSolo')}
                            lp={rankTier.league_points}
                            chartKey={'ranked_solo_5x5'}
                            win={rankTier.wins}
                            lose={rankTier.losses}
                            tierCut={true}
                        />
                    </SpaceDiv>
                    <SpaceDiv>
                        <SummonerMatchesProvider.Rank
                            tier={flexTier.tier}
                            division={flexTier.division}
                            title={t('summoner.rankedFlex')}
                            win={flexTier.wins}
                            lose={flexTier.losses}
                            topPercent={flexTier.ranking_rate >= 0.0001 ? flexTier.ranking_rate.toFixed(4) : 0.0001}
                            rank={flexTier.ranking}
                            lp={flexTier.league_points}
                        />
                    </SpaceDiv>
                    {/*<AdBox>*/}
                    {/*</AdBox>*/}
                    <SummonerMatchesProvider.Champion stats={summonerStat}/>
                    <SideBarAd id={'coupang_ad'}>
                        <iframe
                            src="https://ads-partners.coupang.com/widgets.html?id=799026&template=carousel&trackingCode=AF1158950&subId=&width=304&height=304&tsource="
                            width="302" height="302" frameBorder="0" scrolling="no" referrerPolicy="unsafe-url"
                            browsingtopics></iframe>
                    </SideBarAd>
                    <SideBarAd id={'summoner_sidebar_ad'}>
                    </SideBarAd>
                </ColumnBox>
                <ColumnBox height={'auto'} justifyContent={'flex-start'} margin={'0 0 0 10px'} width={'100%'}>
                    <StoryMatchList matchList={storyApi.matchList.list} />
                </ColumnBox>
            </FlexBox>
        </SummonerMatchesProvider>
    );
};


const MatchListWrapper = styled.div`
  margin-bottom: 15px;
`;
const MarqueeWrapper = styled.div`
  margin-bottom: 4px;
`;
const OnlyMarqueeWrapper = styled.div`
  margin-bottom: 12px;
`;
const StoryMatchList = ({matchList}) => {
    const [pagination, setPagination] = useState(1);

    if(!Array.isArray(matchList)) return null
    if(matchList.length === 0) return null

    return (
        <MatchListContainer>
            <NewsChat filteredList={matchList} pagination={pagination} setPagination={setPagination}/>
        </MatchListContainer>
    )
}

export default Story;